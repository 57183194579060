import $ from "jquery";

export function createAcknowledgements(){
    $("#aboutBody").html(

        '<span>' +
        'This is version: ' + process.env.version +' of the Atlas Application'+
        '<p>' +
        'We would like to express our gratitude to the following open source projects for their contributions to this project:</p>\n' +
        '<ul>\n' +
        '    <li><a href="https://github.com/auth0/auth0-spa-js">auth0/auth0-spa-js</a></li>\n' +
        '    <li><a href="https://github.com/mapbox/sphericalmercator">@mapbox/sphericalmercator</a></li>\n' +
        '    <li><a href="https://github.com/ttskch/select2-bootstrap4-theme">@ttskch/select2-bootstrap4-theme</a></li>\n' +
        '    <li><a href="https://github.com/Turfjs/turf/tree/master/packages/turf-center">@turf/center</a></li>\n' +
        '    <li><a href="https://github.com/Turfjs/turf/tree/master/packages/turf-rewind">@turf/rewind</a></li>\n' +
        '    <li><a href="https://github.com/axios/axios">axios</a></li>\n' +
        '    <li><a href="https://github.com/expressjs/body-parser">body-parser</a></li>\n' +
        '    <li><a href="https://github.com/twbs/bootstrap">bootstrap</a></li>\n' +
        '    <li><a href="https://github.com/feross/buffer">buffer</a></li>\n' +
        '    <li><a href="https://github.com/zenorocha/clipboard.js/">clipboard</a></li>\n' +
        '    <li><a href="https://github.com/motdotla/dotenv">dotenv</a></li>\n' +
        '    <li><a href="https://github.com/browserify/events">events</a></li>\n' +
        '    <li><a href="https://github.com/expressjs/express">express</a></li>\n' +
        '    <li><a href="https://github.com/auth0/express-jwt">express-jwt</a></li>\n' +
        '    <li><a href="https://github.com/auth0/express-jwt-authz">express-jwt-authz</a></li>\n' +
        '    <li><a href="https://github.com/uber/h3-js">h3-js</a></li>\n' +
        '    <li><a href="https://github.com/jquery/jquery">jquery</a></li>\n' +
        '    <li><a href="https://github.com/auth0/node-jwks-rsa">jwks-rsa</a></li>\n' +
        '    <li><a href="https://github.com/openlayers/openlayers">ol</a></li>\n' +
        '    <li><a href="https://github.com/Viglino/ol-ext">ol-ext</a></li>\n' +
        '    <li><a href="https://github.com/walkermatt/ol-layerswitcher">ol-layerswitcher</a></li>\n' +
        '    <li><a href="https://github.com/mholt/PapaParse">papaparse</a></li>\n' +
        '    <li><a href="https://github.com/parcel-bundler/parcel">parcel</a></li>\n' +
        '    <li><a href="https://github.com/brianc/node-postgres">pg</a></li>\n' +
        '    <li><a href="https://github.com/popperjs/popper-core">popper.js</a></li>\n' +
        '    <li><a href="https://github.com/sindresorhus/process">process</a></li>\n' +
        '    <li><a href="https://github.com/select2/select2">select2</a></li>' +
        '    <li><a href="https://github.com/olifolkerd/tabulator" target="_blank">tabulator-tables</a></li>\n' +
        '    <li><a href="https://github.com/jashkenas/underscore" target="_blank">underscore</a></li>\n' +
        '    <li><a href="https://github.com/defunctzombie/node-util" target="_blank">util</a></li>\n' +
        '    <li><a href="https://github.com/defunctzombie/commonjs-assert" target="_blank">assert</a></li>\n' +
        '    <li><a href="https://github.com/devongovett/browserify-zlib" target="_blank">browserify-zlib</a></li>\n' +
        '    <li><a href="https://github.com/crypto-browserify/crypto-browserify" target="_blank">crypto-browserify</a></li>\n' +
        '    <li><a href="https://github.com/browserify/path-browserify" target="_blank">path-browserify</a></li>\n' +
        '    <li><a href="https://github.com/bestiejs/punycode.js" target="_blank">punycode</a></li>\n' +
        '    <li><a href="https://github.com/jstrace/querystring-es3" target="_blank">querystring-es3</a></li>\n' +
        '    <li><a href="https://github.com/substack/stream-browserify" target="_blank">stream-browserify</a>\n' +
        '    <li><a href="https://github.com/jhiesey/stream-http" target="_blank">stream-http</a>\n' +
        '    <li><a href="https://github.com/nodejs/node/blob/master/lib/string_decoder.js" target="_blank">string_decoder</a> </li>\n' +
        '    <li><a href="https://github.com/defunctzombie/node-url" target="_blank">url</a></li>'+
        '<p>We thank these projects for their commitment to open source and for making their code available to the community.</p>'+
        '</span>'
)}