import {map} from "../map";
import {clickedLonLat} from "./coordinateFunctions";
import {clearAssignmentLayer, clearCoordinateSearchLayer} from "../layers/activeLayers";
import {
    clearAssignmentHeaderText,
    clearInfoDiv,
    clearMapUtilsBody,
    clearMapUtilsHeader,
    clearNextButton,
    clearOtherInfoDiv,
    clearPrevButton,
    clearSubMapRow,
    hideAssignManagementButtons,
    hideAssignmentsButton,
    hideAssignmentTable,
    setAssignmentModeToFalse
} from "../supportFunctions/consts";
import {lineHandler} from "../lines/lineHandler";
import {pointHandler} from "../points/pointHandler";
import {streetDefaultStyle} from "../map_styles/lineStyle";
import {checkAssignmentMode} from "../assignments/assignmentActions";
import {checkEditMode} from "../points/pointFunctions";
import {checkInFrameWithoutEdit} from "../points/pointStatuses";
import {polyHandler} from "../polygons/polyHandler";
import {placeDefaultStyle} from "../map_styles/polyStyles";

export function clickControl(event) {
    if (checkEditMode() === true){
        clearCoordinateSearchLayer()
        clearSubMapRow()
        hideAssignmentsButton()
    }
    else if(checkAssignmentMode() === true){
        clearAssignmentLayer()
        clearCoordinateSearchLayer()
        clearMapUtilsBody()
        clearMapUtilsHeader()
        clearOtherInfoDiv()
        clearNextButton()
        clearPrevButton()
        hideAssignmentsButton()
        streetDefaultStyle()
        placeDefaultStyle()
    }
    else {
        clearAssignmentLayer()
        hideAssignmentTable()
        hideAssignManagementButtons()
        clearAssignmentHeaderText()
        clearCoordinateSearchLayer()
        clearMapUtilsBody()
        clearMapUtilsHeader()
        clearOtherInfoDiv()
        clearInfoDiv()
        clearNextButton()
        clearPrevButton()
        streetDefaultStyle()
        placeDefaultStyle()
        clearSubMapRow()
        hideAssignmentsButton()
    }

    //first get the coordinates of the click event
    clickedLonLat(event)

    //if not editable proceed to check content of clicked layer
    if (checkEditMode() === false) {
        const features = map.getFeaturesAtPixel(event.pixel, {
            hitTolerance: 5
        });
        // if there are no features, ie: clicked basemap, clear everything, only coordinates will be displayed.
        if (features.length === 0) {
            streetDefaultStyle();
            placeDefaultStyle()
            //*** cstationDefaultStyle();
        }
        //check if its a LineString(ie: Road segment)
        else if (features[0].getGeometry().getType() === 'LineString'){
            lineHandler(features)
        }
        //check if its a Polygon(ie: Geopath Place)
        else if (features[0].getGeometry().getType() === 'Polygon'){
            polyHandler(features)
        }
        // if not a road segment check if it's a Point (ie: inventory location)
        else if (features[0].getGeometry().getType() === 'Point') {
            if(checkAssignmentMode() === true){
                checkInFrameWithoutEdit()
                setAssignmentModeToFalse()
                hideAssignmentTable()
                hideAssignManagementButtons()
                clearAssignmentHeaderText()
                clearSubMapRow()
                streetDefaultStyle()
                pointHandler(features)
            }
            else{
                streetDefaultStyle()
                placeDefaultStyle()
                pointHandler(features)
            }

        }
    }
}