import {createAssignmentForm} from "./assignmentCreationForm";
import {
    assignmentMode,
    changeAssignmentModeToTrue,
    clearAssignmentHeaderText,
    clearOtherInfoDiv,
    clearSubMapHeader,
    clearSubMapRow,
    hideAssignManagementButtons,
    hideAssignmentTable,
    hideSubMapLoading,
    setAssignmentModeToFalse,
    showAssignmentsButton,
    showSubMapLoading
} from "../supportFunctions/consts";
import {getPanelJSON} from "../points/pointFunctions";
import {
    checkInFrameWithoutEdit,
    updateFrameStatusAfterAssignment,
    updateFrameStatusAfterBulkAssignment
} from "../points/pointStatuses";
import {assignmentDupeChecker, assignmentNewDupeChecker, assignmentOppDupeChecker} from "./assignmentDuplicateCheck";
import $ from "jquery";
import {getAssignmentIdsFromTable,} from "./assignmentInfoDisplay";
import {apiGetAssignments, exisitingAssignments, rawExisitingAssignments} from "./assignmentHandler";
import {assignmentsource, assignmentsource2021, clearAssignmentLayer} from "../layers/activeLayers";
import {getExtentofAssignmentsAndPoints} from "../supportFunctions/mapFunctions";
import {map} from '../map';
import {WKT} from 'ol/format';
import {streetDefaultStyle} from "../map_styles/lineStyle";

//checks the assignment mode to determine if add to assignment gets added to individual line display
export function checkAssignmentMode (){
    return assignmentMode
}

//creates empty arrays for use in the add to assignment cart function
let assignmentFormArray =[];
let oppAssignments = [];

export async function addToAssignmentCart(){
    //gets various information from the various data displays and buttons for use in creating an assignment object
    let panelJSON = getPanelJSON()
    let formSegId = $("#segListForm").val();
    let formSegLanes = $("#segLanes").val();
    let formUser = localStorage.getItem('userEmail');
    let formCenter;
    let formOppSeg = $("#selectedLineOppseg").val();
    let formOppSegWKT = $("#selectedLineOppseggeom").val();
    let formOppSegLanes = $("#oppSegLanes").val();
    let frameOrientation = panelJSON['location']['orientation'];
    let frameConstructionType = panelJSON['construction']['construction_type']['name'];
    let segWKT = $("#selectedLineWKT").val();
    let frameWKT = $("#currentPanelWKT").text();
    if (document.getElementById('center_read').checked) {
        formCenter = 'TRUE'
    } else {
        formCenter = 'FALSE'
    }
    let formMode;
    if (document.getElementById('bothval').checked) {
        formMode = 'B';
        if (formSegId.includes('_P_') === true) {
            formMode = 'P'
        }
    }
    if (document.getElementById('pedonly').checked) {
        formMode = 'P'
    }
    let network_type = 2;
    if (document.getElementById('year2016').checked) {
        network_type = 2;
    }
    if (document.getElementById('year2021').checked) {
        network_type = 6
    }

    let newObj = {
        frame_id: panelJSON['id'],
        segment_id: formSegId,
        mode: formMode,
        network_type: network_type
    };
    if (formOppSeg !== '-1') {
        let newOppObj = {
            frame_id: panelJSON['id'],
            segment_id: formOppSeg,
            mode: 'P',
            network_type: network_type
        };
        oppAssignments.push(newOppObj)
    }
    //checks for duplicates in existing assignments, in opposite segment assignments and in pending assignments in the cart already
    let dupe = await assignmentDupeChecker(newObj);
    let oppDupe = await assignmentOppDupeChecker(oppAssignments, newObj);
    let newDupe = await assignmentNewDupeChecker(assignmentFormArray,newObj)
    if (dupe === false) {
        if (oppDupe === false) {
            if (newDupe === false) {
                //if it passes all dupe checks the creation of the object to add to the assignment array
                let formData = {
                    "geopath_id": panelJSON['id'],
                    "segmentid": formSegId,
                    "segLanes": formSegLanes,
                    "mode": formMode,
                    "center": formCenter,
                    "opposite_seg_id": formOppSeg,
                    "opposite_seg_wkt": formOppSegWKT,
                    "opposite_seg_lanes": formOppSegLanes,
                    "construction_type": frameConstructionType,
                    "user": formUser,
                    "lineWKT": segWKT,
                    "frameWKT": frameWKT,
                    "frameOrientation": frameOrientation,
                    "network_type":network_type
                };
                assignmentFormArray.push(formData);
                // let assignmentFormList = document.getElementById('assignmentFormList');
                //display the new assignment in the cart and stingify the form data for api posting purposes
                $("#assignmentFormList").append('<li class="no-bullet"><button class="fas fa-times" style="color: #fc0703; display: contents"></button>' + JSON.stringify(formData) + '</li>');
                //if the red x is clicked, remove the item
                $(".fas.fa-times").on('click', function (e) {
                    e.preventDefault();
                    this.closest('li').remove();
                })
            }
            else {
                alert("This is a duplicate assignment and will not be added")
            }
        }
        else {
            alert("This is a duplicate assignment and will not be added")
        }
    }
    else {
        alert("This is a duplicate assignment and will not be added")
    }
}

export function submitNewAssignment() {
    showSubMapLoading();
    //get everything in the form cart
    let assignmentlist = document.getElementById('assignmentFormList').getElementsByTagName('li');
    let createassignmentArray = [];
    $(assignmentlist).each(function () {
        createassignmentArray.push($(this).text())
    });
    //set settings for api call
    // console.log(createassignmentArray)
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "/api/createCompleteAssignment",
        "method": "get",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {createassignmentArray}
    };
    // send the form data to the database
    $.ajax(settings).done(function (response) {
        let errors = [];
        let oks = [];
        $(response).each( function (index,value) {
            // console.log(response)
            if(value !== 201){ errors.push(value)}
            else {oks.push(value)}
        });
        if (errors.length > 0){
            hideSubMapLoading();
            clearSubMapRow()
            $("#submaprowHeader").html('<h2>ERROR Present Please check assignments again and correct what has been missed</h2>');
            setTimeout(clearSubMapHeader,5000)
        }
        else {
            apiGetAssignments()
            hideSubMapLoading();
            clearSubMapRow()
            $("#submaprowHeader").html('<h6>Success: '+ oks.length +' Assignments Created</h6>');
            setTimeout(clearSubMapHeader,5000)
            let updated_desc = 'new assignment(s) created via atlas';
            updateFrameStatusAfterAssignment(updated_desc)
            clearOtherInfoDiv()
            streetDefaultStyle()
        }
    });
}

export function createAssignments() {
    assignmentFormArray = []
    oppAssignments = []
    // checkOutFrame()
    createAssignmentForm()
    clearAssignmentLayer()
    changeAssignmentModeToTrue()
    $("#addToAssignmentCart").on('click',function () {
        addToAssignmentCart()
    })
    $("#exitcreateform").on('click',function () {
        checkInFrameWithoutEdit();
        //empty form array and set mode to false
        assignmentFormArray = []
        setAssignmentModeToFalse()
        hideAssignmentTable()
        hideAssignManagementButtons()
        clearSubMapRow()
        apiGetAssignments()
    })
    $("#submitnewassignment").on('click',function () {
        submitNewAssignment()
        //empty form array and set mode to false
        assignmentFormArray = []
        setAssignmentModeToFalse()
    })
    
}

export async function deleteAllAssignments(assignment_id) {
    let assignment_list
    if(assignment_id){
         assignment_list = [assignment_id]
    }
    else{
        assignment_list = getAssignmentIdsFromTable()
    }
    let frameObj = JSON.parse($('#panelInfoDropDownText').text());
    let user = localStorage.getItem('userEmail');
    let accessToken = "Bearer " + localStorage.getItem('access_token');
    let responseArray =[];
    let errorArray = [];
    let deletePromises = [];
    $.each(assignment_list, function (index,value) {
        deletePromises.push(new Promise(function (resolve) {
            let formData = {
                "geopath_id": frameObj['id'],
                "assignment_id": value,
                "userEmail": user
            };
            let settings = {
                "url": process.env.appurl + "/api/deleteAssignment/",
                "method": "get",
                "content-type": "application/json",
                "headers": {
                    "Authorization": accessToken
                },
                "data": formData
            };
            $.ajax(settings).done(function (response) {
                if(response === 'OK'){
                    responseArray.push('OK');
                    resolve()
                }
                else{
                    errorArray.push('NOT OK');
                    resolve()
                }
            })
        }))

    });
    await Promise.all(deletePromises).then(function () {
        if (assignment_list.length === responseArray.length){
            hideAssignmentTable()
            hideAssignManagementButtons()
            clearAssignmentHeaderText()
            clearAssignmentLayer()
            streetDefaultStyle()
            apiGetAssignments();
            let updated_desc = 'assignment(s) deleted via atlas';
            updateFrameStatusAfterAssignment(updated_desc)
            hideSubMapLoading()
            getExtentofAssignmentsAndPoints()
        }
        else {
            $("#submaprowHeader").html('<h5>Error, please try again</h5>')
        }
    })

}

export async function reCalcAssignments() {
    showSubMapLoading()
    //set extent to map to the combo of the rotate layer and assignment layer
    getExtentofAssignmentsAndPoints()
    //set variables and get assignment info from the layer source and frame info from the json
    let assignmentList = [];
    let assignments = []
    $.each(assignmentsource.getFeatures(),function (i,v){
        assignments.push(v)
    })
    $.each(assignmentsource2021.getFeatures(),function (i,v){
        assignments.push(v)
    })
    let frame = JSON.parse($('#panelInfoDropDownText').text());
    // console.log(assignments)
    // console.log(exisitingAssignments)
    //loop through the existing assignments list created when we got the assignments
    $.each(exisitingAssignments, function (index,value) {
        //sub loop through the layer assignments to get the various geoms
        $.each(assignments, function (i, v) {
            let segterm
            if (value['network_year'] === 2021){
                segterm = 'segment'
            }
            else{
                segterm = 'segment_reg'
            }
            if (v['values_'][segterm] === value['segment_id']){

                value.linegeom = v
                value.lineWKT = new WKT().writeFeature(v)
                let featgeom = v.getGeometry();
                let coordinate = featgeom.getCoordinates();
                value.pixel = map.getPixelFromCoordinate(coordinate[1])

            }
        });
        //loop through the original assignment objects from when the assignments were found and apply as an object to finish the final existing assignment object
        $.each(rawExisitingAssignments, function (indx,val) {
            // console.log('segment matching')
            // console.log(val)
            // console.log(value)
            if(value['network_year'] === 2021){
                if (val['segment_id'] === value['segment_id'] && val['network_type']['id'] === 6){
                    value.original_assignment_object = val
                }
            }
            else if (value['network_year'] === 2016){
                if (val['segment_id'] === value['segment_id'] && val['network_type']['id'] === 2){
                    value.original_assignment_object = val
                }
            }

        })
    });
    //go through the existing assignments and get the opposite segment information
    $.each(exisitingAssignments, function (index, value) {
        let segterm
        if (value['network_year'] === 2021){
            segterm = 'segment'
        }
        else{
            segterm = 'segment_reg'
        }
        let formData = {
            "geopath_id": frame['id'],
            "assignment_id": value['assignment_id'],
            "segmentid": value['linegeom']['values_'][segterm],
            "segLanes": value['linegeom']['values_']["lanes"],
            "mode": value['mode'],
            "center": value['center_read'],
            "opposite_seg_id": value['linegeom']['values_']["segment_r"],
            "construction_type": frame['construction']['construction_type']['name'],
            "user": localStorage.getItem('userEmail'),
            "lineWKT": value['lineWKT'],
            "frameWKT": $("#currentPanelWKT").text(),
            "frameOrientation": frame['location']['orientation'],
            "original_assignment_object": value['original_assignment_object']
        };
        assignmentList.push(formData)
    });
    // console.log(assignmentList)
    let settings = {
        "url": process.env.appurl + "/api/reCalcAssignment",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data": {"dataList":JSON.stringify(assignmentList)}
    };
    let existingstatus = frame['status_type']['id']
    $.ajax(settings).done(function (response) {
        if(response[0] !== 200){
            hideSubMapLoading();
            hideAssignmentTable()
            hideAssignManagementButtons()
            $("#submaprowHeader").html('<h5>ERROR Present Please Recalculate again</h5>');
            setTimeout(clearSubMapHeader,5000)
        }
        else {
            let updated_desc = 'assignment VAI scores recalculated due to attribute change'
            // console.log('updating status after assignment')
            updateFrameStatusAfterAssignment(updated_desc)
            hideSubMapLoading();
            hideAssignmentTable()
            hideAssignManagementButtons()
            $("#submaprowHeader").html('<h6>Success, Assignments Recalculated</h6>')
            setTimeout(clearSubMapHeader,5000)
            // console.log('refreshing api info after assignment recalc')
            apiGetAssignments()
            showAssignmentsButton()
        }

    })

}

export function updateAssignmentsFromForm(data) {
    let newmode = data['mode'];
    let newright = data['right_read'];
    let newperp = data['perpendicular'];
    let newcenter = data['center_read'];
    let assignID = data['assignment_id'];
    let geopathid = $('#currentGeoID').text();
    let email = localStorage.getItem('userEmail');
    let settings = {
        "url": process.env.appurl + "/api/updateAssignmentMode/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data":{
            "geopath_id":geopathid,
            "assignment_id":assignID,
            "newMode":newmode,
            "newRight": newright,
            "newCenter":newcenter,
            "newPerp":newperp,
            "email":email,
        }
    };
    $.ajax(settings).done(function () {
        apiGetAssignments();
    })
}

export async function makePlaceBasedAssignments(data){
    $("#placeAssignSpinner").attr('hidden', false)
    let assignment_success = []
    let assignment_fails = []
    let assignmentPromises = []
    $.each(data,function (index,value) {
        assignmentPromises.push(new Promise(function (resolve){
            let assignment = {
                "frame_id": value["frame_id"],
                "email": localStorage.getItem('userEmail'),
                "assignment_type": {
                    "id": 5
                },
                "network_type": {
                    "id": 4
                },
                "segment_id":-1,
                "place_id": value["place_id"]
            }
            let assignmentString = JSON.stringify(assignment)
            let settings = {
                "async": false,
                "url": process.env.appurl + "/api/createPlaceBasedAssignment/",
                "method": "post",
                "content-type": "application/json",
                "headers": {
                    "Authorization": "Bearer " + localStorage.getItem('access_token')
                },
                "data":{"postbody":assignmentString}
            }
            // console.log(settings)
            $.ajax(settings).done(function (response) {
                // console.log(response)
                if(response['status'] === 201){
                    assignment_success.push(value['frame_id'])
                    // console.log('updating status')
                    updateFrameStatusAfterBulkAssignment("place assignment updated via bulk form in atlas",value['frame_id'])
                    resolve()
                }
                else{
                    assignment_fails.push(value['frame_id'])
                    resolve()
                }
            })
        }))
    })
    await Promise.all(assignmentPromises).then(function () {
        $("#placeAssignProgressMsg").html(
            '<span>Assignments Succeeded: ' + assignment_success.length +'</span><br>'+
            '<span>Assignments Failed: ' + assignment_fails.length +'</span><br>'
        )
        if(assignment_fails.length > 0 ){
            $("#placeAssignProgressMsg").append('<span>The Following Frames Failed: </span><br>')
            $.each(assignment_fails, function (index,value){
                $("#placeAssignProgressMsg").append('<span>' + value +'</span><br>')
            })
        }
        $("#placeAssignSpinner").attr('hidden', true)
    })

}