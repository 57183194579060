import $ from "jquery"
import {createEditInventoryForm} from "../infoDisplays/editInventoryForm";

export function intialiseInvetoryEdit(frame_id){
    let formData = {
        "geopath_id": frame_id
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getFrameInfo',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": formData
    };
    $.ajax(settings).done(function (response) {
        if (response['data']){
            // console.log(response)
            createEditInventoryForm(response['data'])
            // populateEditInventoryForm(response['data'])
        }
        else {
            alert('Frame Not Found, please double check')
        }


    })


}
// export function editInventoryFromForm(){
//     // console.log('editing from form')
//     return
// }