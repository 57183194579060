import $ from 'jquery'
import 'ol-ext/dist/ol-ext.css'
import {convertIntoFt, degrees_to_radians, round} from "../supportFunctions/mathFunctions";
import {transform as transformCoords} from 'ol/proj';
import {clearAssignmentLayer, clearRotateLayer, rotateLayer} from "../layers/activeLayers";
import {getPanelJSON} from "./pointFunctions";
import {transformInteraction} from "../map";
import {selectedStyle} from "../map_styles/pointStyles";
import {
    changeEditModeToTrue,
    clearAssignmentHeaderText,
    clearMapUtilsBody,
    clearMapUtilsHeader,
    clearOtherInfoDiv,
    clearRedundantFields,
    clearSubMapRow,
    currentpanelgeojson,
    hideAssignManagementButtons,
    hideAssignmentsButton,
    hideAssignmentTable,
    hideInfoLoading,
    setEditModeToFalse,
    showAssignmentsButton,
    showInfoLoading
} from "../supportFunctions/consts";
import {apiGetFrame} from "./pointHandler";
import {streetDefaultStyle} from "../map_styles/lineStyle";
import {checkInFrameWithoutEdit, removeFromAuditLog, statusActionLookup} from "./pointStatuses";
import {refreshInvVectorTile} from "../layers/vectorTileLayers";
import {apiGetAssignments, shapePromises} from "../assignments/assignmentHandler";
import {reCalcAssignments} from "../assignments/assignmentActions";
import {createPointWKTfromCoords, transform4326to3857} from "../supportFunctions/mapFunctions";
import {createFrameEditor} from "../infoDisplays/editIndividualFrame";
import {prepFrameInfoDisplay} from "../infoDisplays/frameInfo";

//this creates the frame editor and populates the dropdowns from the initial API loads
export function frametf(x){
    return x === 1;
}

export function editPanelDisplay () {
    let features = getPanelJSON()
    changeEditModeToTrue()
    createFrameEditor(features)

    $("#editorSave").html('<button id="saveEdits" type="button" class="btn btn-danger btn-sm">Save Edits</button>')
    $("#editorExit").html('<button id="editExit" type="button" class="btn btn-primary btn-sm">Exit Without Saving</button>')
    // $("#editSpotsbtn").on('click', function (){
    //     editLayouts(features)
    // })
    $("#editExit").on('click', function () {
        transformInteraction.setActive(false);
        clearRotateLayer()
        streetDefaultStyle()
        clearAssignmentLayer()
        hideAssignManagementButtons()
        hideAssignmentTable()
        clearAssignmentHeaderText()
        clearSubMapRow()
        clearMapUtilsHeader()
        clearMapUtilsBody()
        setEditModeToFalse()
        checkInFrameWithoutEdit();
        apiGetFrame(features["id"]);
        $("#editorSave").html('');
        $("#editorExit").html('');

    });
    $("#saveEdits").on('click',function () {
        submitInventoryUpdate(features)
    })
}

//this creates the ability to move the inventory point around the map and updates the corresponding fields in the panel
export function spatialEditInventory(){
    let features = getPanelJSON()
    transformInteraction.setActive(true);
    editPanelDisplay();
    let firstPoint = false;
    let startangle = features["location"]["orientation"];
    let newangle = 0;
    let d=[features["location"]["longitude"],features["location"]["latitude"]];
    transformInteraction.on (['translatestart'], function(e){
        d= e['coordinate']
    });
    transformInteraction.on('rotating', function (e){
        newangle = ((e.angle*180/Math.PI -180)%360+180);
        if (newangle < 0){
            newangle = newangle+360
        }
        else {
            newangle
        }
        $('#currentOrientation').val(round(newangle,0));
        rotateLayer.setStyle(selectedStyle(degrees_to_radians(newangle)))
    });
    transformInteraction.on('translating', function (e){
        d[0]=e.coordinate[0];
        d[1]=e.coordinate[1];
        let translatedCoords = transformCoords([d[0],d[1]],"EPSG:3857","EPSG:4326");
        $('#currentLon').val(round(translatedCoords[0],6));
        $('#currentLat').val(round(translatedCoords[1],6));
    });
    transformInteraction.on(['translateend'], function () {
        let translatedCoords = transformCoords([d[0],d[1]],"EPSG:3857","EPSG:4326");
        $('#currentLon').val(round(translatedCoords[0],6));
        $('#currentLat').val(round(translatedCoords[1],6));
    });
}

//this parses the layout data to populate the layout fields in the content info
export async function parseLayoutData(data){
    let layoutjson = []
    $.each(data['layouts'], function (lindex,value){
            layoutjson.push({
                "Layout_ID":value["id"],
                "Layout_SOV":value["share_of_voice"],
                "faces":[]

            })
            $.each(data['layouts'][lindex]['faces'], function (findex,value){
                layoutjson[lindex]['faces'].push({
                    "Face_ID":value['id'],
                    "Publisher_Unique_Face_ID":value['publisher_unique_face_id'],
                    "Face_Height_Feet":convertIntoFt(value['height']),
                    "Face_Width_Feet":convertIntoFt(value['width']),
                    "Face_Height":value['height'],
                    "Face_Width":value['width'],
                    "Spots_in_Rotation":value['spots_in_rotation'],
                    "Avg_Spot_Length":value['average_spot_length'],
                    "spots":[]
                })
                $.each(data['layouts'][lindex]['faces'][findex]['spots'], function (sindex,value){
                    layoutjson[lindex]['faces'][findex]['spots'].push({
                        "Spot_ID": value['id'],
                        "Operator_Spot_ID": value["plant_spot_id"],
                        "Publisher_Unique_ID": value["publisher_unique_id"],
                        "Spot_Length": value["length"],
                        "Spot_SOV": value["share_of_voice"]
                    })
                });
            });
        }
    )
    return layoutjson
}

export async function submitInventoryUpdate(frameObj) {
    let frameheightinches
    let framewidthinches
    if (isNaN(parseInt($("#currentFrameHeightFeet").val())) === false){
        frameheightinches = (parseInt($("#currentFrameHeightFeet").val())*12) + parseInt($("#currentFrameHeightInches").val())
    }
    else{
        frameheightinches = parseInt($("#currentFrameHeightInches").val())
    }
    if (isNaN(parseInt($("#currentFrameWidthFeet").val())) === false){
        framewidthinches = (parseInt($("#currentFrameWidthFeet").val())*12) + parseInt($("#currentFrameWidthInches").val())
    }
    else{
        framewidthinches = parseInt($("#currentFrameWidthInches").val())
    }


    // console.log(frameObj)
    let actiontype
    apiGetAssignments()
    let formData = {
        "geopath_id": $('#currentpanelid').text(),
        "orientation": $('#currentOrientation').val(),
        "longitude": $('#currentLon').val(),
        "latitude": $('#currentLat').val(),
        "plantframeid": $('#currentOpId').val(),
        "construction_name": $('#currentConsName').val(),
        "construction_type_id": $('#currentConsTypName').select2('data')[0]['id'],
        "construction_date": $('#currentConsDate').val(),
        "placement_type_id": $("#currentPlacementTypeName").select2('data')[0]['id'],
        "media_type_id": $('#currentMedTypName').select2('data')[0]['id'],
        "frame_media_name": $('#currentFrMedName').val(),
        "frame_height": frameheightinches,
        "frame_width": framewidthinches,
        "frame_pixel_height": $('#currentFrPxHt').val(),
        "frame_pixel_width": $('#currentFrPXWd').val(),
        "digital": $('#currentFrDg').val(),
        "illumination_start": $('#currentFrIllSt').val(),
        "illumination_end": $('#currentFrIllEnd').val(),
        "illumination_type_id":$('#currentFrIllType').select2('data')[0]['id'],
        "frame_status_id":$('#currentFrStat').select2('data')[0]['id'],
        "classification_id":$('#currentFrClass').select2('data')[0]['id'],
        "updated_desc":$('#currentUpdateDesc').val(),
        "description":$('#currentUpdateFrDesc').val(),
        "primary_artery":$('#currentPrimArt').val(),
        "primary_read":$('#currentRead').val(),
        "full_motion":$('#currentFrfull_motion').val(),
        "partial_motion":$('#currentFrpartial_motion').val(),
        "rotating":$('#currentFrrotating').val(),
        "interactive":$('#currentFrinteractive').val(),
        "audio":$('#currentFraudio').val(),
        "engagement": $("#currentFrEngage").val()
    };
    let contentData = $("#layoutEditorHolderDiv .layouteditordetail")
    let contentArray = []
    let spotlist = []
    $.each(contentData, function (index,value){
        let idsstring = String($(value).attr('id'))
        contentArray.push({
            type:idsstring.split('%')[0],
            id:parseFloat(idsstring.split('%')[2]),
            field:idsstring.split('%')[1],
            value:$(value).val()
        })
        if(idsstring.split('%')[0]==='spot'){
            spotlist.push(parseFloat(idsstring.split('%')[2]))
        }
    })
    // console.log(contentArray)
    // if (checkAccountForAppeal(frameObj['representations'][0]['account']['id']) === true){
    //     frameObj["status_type"]["id"] = 54;
    //     frameObj['updated_desc'] = "Location/Orientation changed based on available information via Atlas and frame held for appeal";
    // }
        // else if (parseFloat(frameObj["status_type"]["id"]) !== parseFloat(formData.frame_status_id)){
        //     frameObj["status_type"]["id"] = parseFloat(formData.frame_status_id)
        //     if(formData.updated_desc){
        //         frameObj['updated_desc'] = formData.updated_desc
        //     }
        //     else{
        //         frameObj['updated_desc'] = "Status and attributes changed via Atlas";
        //     }
    // }
    if(parseFloat(frameObj["location"]["orientation"]) !== parseFloat(formData.orientation)) {
        actiontype = "spatial"
        statusActionLookup(frameObj['status_type']['id'],"spatial",frameObj['representations'][0]['account']['id'])
        if(formData.updated_desc){
            frameObj['updated_desc'] = formData.updated_desc
        }
        else{
            frameObj['updated_desc'] = "Spatial attributes changed via Atlas";
        }
    }
    else if(parseFloat(frameObj["location"]["latitude"]) !== parseFloat(formData.latitude)) {
        actiontype = "spatial"
        statusActionLookup(frameObj['status_type']['id'],"spatial",frameObj['representations'][0]['account']['id'])
        if(formData.updated_desc){
            frameObj['updated_desc'] = formData.updated_desc
        }
        else{
            frameObj['updated_desc'] = "Spatial attributes changed via Atlas";
        }
    }
    else if(parseFloat(frameObj["location"]["longitude"]) !== parseFloat(formData.longitude)) {
        actiontype = "spatial"
        statusActionLookup(frameObj['status_type']['id'],"spatial",frameObj['representations'][0]['account']['id'])
        if(formData.updated_desc){
            frameObj['updated_desc'] = formData.updated_desc
        }
        else{
            frameObj['updated_desc'] = "Spatial attributes changed via Atlas";
        }
    }
    else {
        actiontype = "nonspatial"
        statusActionLookup(frameObj['status_type']['id'],"other",frameObj['representations'][0]['account']['id'])
        if(formData.updated_desc){
            frameObj['updated_desc'] = formData.updated_desc
        }
        else{
            frameObj['updated_desc'] = "Attributes changed via Atlas";
        }
    }
    // else {
    //     frameObj["status_type"]["id"] = 31;
    //     if(formData.updated_desc){
    //         frameObj['updated_desc'] = formData.updated_desc
    //     }
    //     else{
    //         frameObj['updated_desc'] = "Attributes changed via Atlas";
    //     }
    // }
    parseFloat(frameObj["location"]["orientation"] = formData.orientation);
    parseFloat(frameObj["location"]["longitude"] = formData.longitude);
    parseFloat(frameObj["location"]["latitude"] = formData.latitude);
    frameObj["plant_frame_id"] = formData.plantframeid
    frameObj["construction"]['name'] = formData.construction_name
    frameObj["construction_date"] = formData.construction_date
    frameObj["construction"]["construction_type"]['id'] = formData.construction_type_id
    if(formData.placement_type_id){
        frameObj['placement_type']['id'] = formData.placement_type_id
    }
    frameObj['classification_type']['id'] = formData.classification_id
    frameObj["media_type"]['id'] = formData.media_type_id
    frameObj["media_name"] = formData.frame_media_name
    frameObj['digital'] = formData.digital
    frameObj['full_motion'] = formData.full_motion
    frameObj['partial_motion'] = formData.partial_motion
    frameObj['rotating'] = formData.rotating
    frameObj['interactive'] = formData.interactive
    frameObj['audio'] = formData.audio
    frameObj['engagement'] = formData.engagement

    parseFloat(frameObj['max_height'] = formData.frame_height)
    parseFloat(frameObj['max_width'] = formData.frame_width)
    frameObj['illumination_start_time'] = formData.illumination_start
    frameObj['illumination_end_time'] = formData.illumination_end
    frameObj['illumination_type']['id'] = formData.illumination_type_id
    frameObj['updated_desc'] = formData.updated_desc
    frameObj['description'] = formData.description
    frameObj['location']['primary_artery'] = formData.primary_artery
    frameObj['location']['primary_read'] = formData.primary_read
    // console.log(frameObj)
    // console.log(contentArray)
    $.each(contentArray,function (index,value){
        $.each(frameObj['layouts'],function (framelindex,framelvalue){
            if(value['type']==='layout'){
                if (framelvalue['id'] === value['id']){
                    if(value['field']==='share_of_voice'){
                        frameObj['layouts'][framelindex]['share_of_voice'] = parseFloat(value['value'])
                    }
                    if(value['field']==='full_motion'){
                        frameObj['layouts'][framelindex]['full_motion'] = value['value']
                    }
                    if(value['field']==='partial_motion'){
                        frameObj['layouts'][framelindex]['partial_motion'] = value['value']
                    }
                    if(value['field']==='rotating'){
                        frameObj['layouts'][framelindex]['rotating'] = value['value']
                    }
                    if(value['field']==='interactive'){
                        frameObj['layouts'][framelindex]['interactive'] = value['value']
                    }
                    if(value['field']==='audio'){
                        frameObj['layouts'][framelindex]['audio'] = value['value']
                    }
                }
            }
            $.each(frameObj['layouts'][framelindex]['faces'], function (framefindex,framefvalue){
                if(value['type']==='face'){
                    if (framefvalue['id'] === value['id']){
                        let faceheightinches
                        let facewidthinches
                        if (isNaN(parseInt($(document.getElementById('face%heightFt%' + framefvalue['id'])).val())) === false){
                            faceheightinches = (parseInt($(document.getElementById('face%heightFt%' + framefvalue['id'])).val())*12) + parseInt($(document.getElementById('face%heightIn%' + framefvalue['id'])).val())
                        }
                        else{
                            faceheightinches = parseInt($(document.getElementById('face%heightIn%' + framefvalue['id'])).val())
                        }
                        if (isNaN(parseInt($(document.getElementById('face%widthFt%' + framefvalue['id'])).val())) === false){
                            facewidthinches = (parseInt($(document.getElementById('face%widthFt%' + framefvalue['id'])).val())*12) + parseInt($(document.getElementById('face%widthIn%' + framefvalue['id'])).val())
                        }
                        else{
                            facewidthinches = parseInt($(document.getElementById('face%widthIn%' + framefvalue['id'])).val())
                        }

                        if(value['field']==='publisher_unique_face_id'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['publisher_unique_face_id'] = value['value']
                        }
                        if(value['field']==='heightIn'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['height'] = faceheightinches
                        }
                        if(value['field']==='widthIn'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['width'] = facewidthinches
                        }
                        if(value['field']==='spots_in_rotation'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['spots_in_rotation'] = parseFloat(value['value'])
                        }
                        if(value['field']==='average_spot_length'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['average_spot_length'] = parseInt(value['value'])
                        }
                        if(value['field']==='pixel_height'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['pixel_height'] = parseFloat(value['value'])
                        }
                        if(value['field']==='pixel_width'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['pixel_width'] = parseFloat(value['value'])
                        }
                        if(value['field']==='top_left_pixel_y'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['top_left_pixel_y'] = parseFloat(value['value'])
                        }
                        if(value['field']==='top_left_pixel_x'){
                            frameObj['layouts'][framelindex]['faces'][framefindex]['top_left_pixel_x'] = parseFloat(value['value'])
                        }
                    }
                }
                $.each(frameObj['layouts'][framelindex]['faces'][framefindex]['spots'], function (framesindex,framesvalue){
                    // console.log(spotlist)
                    // console.log(framesvalue)
                    if(value['type']==='spot'){
                        if(framesvalue){
                            if(spotlist.includes(framesvalue['id'])){
                                if (framesvalue['id'] === value['id']){
                                    if(value['field']==='publisher_unique_id'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['publisher_unique_id'] = value['value']
                                    }
                                    if(value['field']==='plant_spot_id'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['plant_spot_id'] = value['value']
                                    }
                                    if(value['field']==='length'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['length'] = parseFloat(value['value'])
                                    }
                                    if(value['field']==='share_of_voice'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['share_of_voice'] = parseFloat(value['value'])
                                    }
                                    if(value['field']==='full_motion'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['full_motion'] = value['value']
                                    }
                                    if(value['field']==='partial_motion'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['partial_motion'] = value['value']
                                    }
                                    if(value['field']==='rotating'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['rotating'] = value['value']
                                    }
                                    if(value['field']==='interactive'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['interactive'] = value['value']
                                    }
                                    if(value['field']==='audio'){
                                        frameObj['layouts'][framelindex]['faces'][framefindex]['spots'][framesindex]['audio'] = value['value']
                                    }
                                }
                            }
                            else {
                                // frameObj['layouts'][framelindex]['faces'][framefindex]['spots'].splice(framesindex,1)
                            }
                        }

                    }
                })
            })

        })
    })
    clearRedundantFields(frameObj);
    // console.log(frameObj)
    let frameString = JSON.stringify(frameObj);
    let settings = {
        "url": process.env.appurl + "/api/updateFrame/",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data":{ "frameObj":frameString}
    };
    let newcoords = transform4326to3857(formData.longitude,formData.latitude)
    createPointWKTfromCoords(newcoords[0],newcoords[1])
    // console.log(frameString)
    // send the form data to the database
    $.ajax(settings).done(async function (response) {
        let getFramePromises =[]
        // console.log(response)
        getFramePromises.push(new Promise(function (resolve){
            let formData = {
                "geopath_id": frameObj['id']
            };
            let settings = {
                "async": true,
                "crossDomain": true,
                "url": process.env.appurl + '/api/getFrameInfo',
                "method": "GET",
                "headers": {
                    "Content-Type": "application/x-www-form-urlencoded/",
                    "Authorization": "Bearer " + localStorage.getItem('access_token')
                },
                "data": formData
            };
            showInfoLoading();
            $("#currentpanelid").text(frameObj['id']);
            $.ajax(settings).done(function (response) {
                // console.log(response)
                clearOtherInfoDiv()
                currentpanelgeojson.text(JSON.stringify(response.data))
                prepFrameInfoDisplay(response['data'])
                showAssignmentsButton()
                let newcoords = transform4326to3857(response['data']['location']['longitude'],response['data']['location']['latitude'])
                createPointWKTfromCoords(newcoords[0],newcoords[1])
                hideInfoLoading();
                resolve()
            })
        }))
        // apiGetFrame(frameObj['id']);
        await Promise.all(getFramePromises).then( async function (){
            if (actiontype === "spatial") {
                // console.log('recalculting assignments')
                await Promise.all(shapePromises).then(
                    reCalcAssignments
                )
            }
            hideAssignmentsButton()
            refreshInvVectorTile()
            removeFromAuditLog()
            transformInteraction.setActive(false);
            clearRotateLayer()
            // streetDefaultStyle()
            // clearAssignmentLayer()
            // submapclear()
            clearMapUtilsBody()
            clearMapUtilsHeader()
            setEditModeToFalse()
            $("#editorSave").html('');
            $("#editorExit").html('');
        })


    })
}




