import $ from "jquery";
import {placebasedassignmentlayer, placebasedassignmentsource} from "../layers/activeLayers";
import {Fill, Stroke, Style} from "ol/style";

function placeReturner () {
    let featureprop = placebasedassignmentlayer.getProperties();
    let featureprops = featureprop["source"]["featuresRtree_"]["items_"];
    $.each(featureprops, function (index,value) {
        let ol_uid = value["value"]["ol_uid"];
        $.each(placebasedassignmentsource.getFeatures(),function (index,value){
            if(value['ol_uid'] === ol_uid){
                value.setStyle(
                    new Style({
                        stroke: new Stroke({
                            color: [0, 131, 154],
                            width: 1,
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 0, 255, 0.1)',
                        }),
                    }),
                );
            }
        })
    });
}

export function flashPlace (place_id){
    // console.log(place_id)
    let featureprop = placebasedassignmentlayer.getProperties();
    // console.log(featureprop)
    let featureprops = featureprop["source"]["featuresRtree_"]["items_"];
    // console.log(featureprops)
    $.each(featureprops, function (index,value) {
        if (value["value"]["values_"]["place_id"] === place_id) {
            let ol_uid = value["value"]["ol_uid"];
            // console.log(ol_uid)
            $.each(placebasedassignmentsource.getFeatures(),function (index,value){
                // console.log(value)
                if(value['ol_uid'] === ol_uid){
                    value.setStyle(
                        new Style({
                            stroke: new Stroke({
                                color: [247, 17, 17],
                                width: 25,
                            }),
                            fill: new Fill({
                                color: 'rgba(247, 17, 17, 0.1)',
                            }),
                        }),
                    );
                }
            })
        }
    });
    setTimeout(placeReturner,3000)
}