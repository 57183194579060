import {info} from "../supportFunctions/consts";
import {formatNumber, round} from "../supportFunctions/mathFunctions";

export function totLineInfo(numfeats,trafficAggs,directionAggs,link_id,st_name) {
    let stname
    if (st_name === ''){
        stname = 'No Street Name'
    }
    else{
        stname = st_name
    }
    info.html(
        '<h4> Total Link Info</h4>' +
        '<table class="table table-striped table-bordered table-sm">'+
            '<tbody>' +
        '    <tr class="table-info">' +
        '      <th scope="row">HERE Link ID</th>' +
        '      <td>'+ link_id +'</td>\n' +
        '    </tr><tr>'+
        '      <th scope="row">Street Name</th>' +
        '      <td>'+ stname +'</td>\n' +
        '    </tr><tr>'+
        '      <th scope="row">Number of Segments</th>' +
        '      <td>'+ numfeats +'</td>\n' +
        '    </tr><tr>'+
        '      <th scope="row">Directions</th>' +
        '      <td>'+ directionAggs +'</td>\n' +
        '    </tr><tr>'+
        '      <th scope="row">Veh Total Circulation</th>' +
        '      <td>'+ formatNumber(round(trafficAggs[0]['veh_tot_circ'], 0)) +'</td>\n' +
        '    </tr><tr>'+
        '      <th scope="row">Veh Total AADT</th>' +
        '      <td>'+ formatNumber(round(trafficAggs[0]['veh_tot_aadt'], 0)) +'</td>\n' +
        '    </tr><tr>'+
        '      <th scope="row">Ped Total AADT</th>' +
        '      <td>'+ formatNumber(round(trafficAggs[0]['ped_tot'], 0)) +'</td>\n' +
        '    </tr>'
    )
}