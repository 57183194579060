import {place_vt_ly} from "../layers/vectorTileLayers";
import {Fill, Stroke, Style} from "ol/style";
import {placebasedassignmentlayer} from "../layers/activeLayers";


export function selectedPlaceStyle(idProp){
    place_vt_ly.setStyle(function (feature) {
        const properties = feature.getProperties();
        if (properties.place_id === idProp) {
            return new Style({
                stroke: new Stroke({
                    color: [227,74,51],
                    width: 1,
                }),
                fill: new Fill({
                    color: 'rgba(227,74,51, 0.1)',
                }),
            })
        } else {
            return new Style({
                stroke: new Stroke({
                    color: [0, 131, 154],
                    width: 1,
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0.1)',
                }),
            })
        }
    });
}

export function placeDefaultStyle(){
    place_vt_ly.setStyle(function () {
        return new Style({
            stroke: new Stroke({
                color: [0, 131, 154],
                width: 1,
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)',
            }),
        })
    })
}

export function geojsonplaceDefaultStyle(){
    placebasedassignmentlayer.setStyle(function () {
        return new Style({
            stroke: new Stroke({
                color: [0, 131, 154],
                width: 1,
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)',
            }),
        })
    })
}