export const arrayAdd = (a, b) => a + b

export function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

export function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function convertIntoFt(inches) {
    let feetFromInches = Math.floor(inches / 12);//There are 12 inches in a foot
    let inchesRemainder = inches % 12;
    return feetFromInches + "'-" + inchesRemainder + "\"";
}
export function convertIntoFtArray(inches) {
    let feetFromInches = Math.floor(inches / 12);//There are 12 inches in a foot
    let inchesRemainder = inches % 12;
    return [feetFromInches,inchesRemainder];
}
export function convertFttoIn(feet,inches){
    let inchesfromfeet = Number(feet)*12
    return inchesfromfeet + Number(inches)
}

export function degrees_to_radians (degrees) {
    let pi = Math.PI;
    return degrees * (pi/180);
}

export function degrees_to_orientation (orientation) {
    if (orientation >= 0 && orientation < 45){
        return "N"
    }
    if (orientation >= 45 && orientation < 90){
        return "NE"
    }
    if (orientation >= 90 && orientation < 135){
        return "E"
    }
    if (orientation >= 135 && orientation < 180){
        return "SE"
    }
    if (orientation >= 180 && orientation < 225){
        return "S"
    }
    if (orientation >= 225 && orientation < 270){
        return "SW"
    }
    if (orientation >= 270 && orientation < 345){
        return "W"
    }
    if (orientation >= 315 && orientation < 360){
        return "NW"
    }
    else {
        return orientation
    }
}

export function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const A = a.text.toUpperCase();
    const B = b.text.toUpperCase();

    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison;
}

export function randomNum() {
    return Math.floor(Math.random() * 256);
}