import $ from "jquery";
import {clearAssignmentLayer, clearRotateLayer} from "../layers/activeLayers";
import {
    clearInfoDiv,
    clearOtherInfoDiv,
    clearSubMapRow,
    hideAssignManagementButtons,
    hideAssignmentsButton,
    hideAssignmentTable,
    hideInfoLoading,
    setAssignmentModeToFalse,
    showAssignmentsButton,
    showInfoLoading
} from "../supportFunctions/consts";
import {streetDefaultStyle} from "../map_styles/lineStyle";
import {placeDefaultStyle, selectedPlaceStyle} from "../map_styles/polyStyles";
import {prepPlaceInfoDisplay} from "../infoDisplays/placeInfo";


export function apiGetPlace(place_id) {
    $("#currentpanelstuff").attr('hidden',false);
    let formData = {
        "place_id": place_id
    };
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + '/api/getPlaceInfo',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": formData
    };
    showInfoLoading();
    $.ajax(settings).done(function (response) {
        clearOtherInfoDiv()
        prepPlaceInfoDisplay(response['data'])
        showAssignmentsButton()
        hideInfoLoading();
    })
}

export function currentPlaceParse(feature){
    // console.log(feature)
    let props = feature['values_']
    let selectedPlaceID = props['place_id']
    clearRotateLayer();
    //set map style
    selectedPlaceStyle(selectedPlaceID)
    $("#currentpanelstuff").attr('hidden',false);
    //go get detailed info
    apiGetPlace(selectedPlaceID)
}

export function polyHandler(allfeatures){
    let features = allfeatures
    let numfeats = features['length']
    let i =0
    //paginate results if more than 1
    if (numfeats > 1) {
        $("#mapUtilsHeader").prop('hidden',false)
        $("#mapUtilsHeader").html('<h6>'+numfeats +' Features Selected</h6>')
        $("#nextbutton").html('<button id="infonext" type="button" class="btn btn-primary btn-sm">Next</button>');
        $("#prevbutton").html('<button id="infoprev" type="button" class="btn btn-primary btn-sm" disabled>Previous</button>');
    }
    // set rules for paging through results
    $('#infonext').on('click', function () {
        clearAssignmentLayer()
        clearInfoDiv()
        clearOtherInfoDiv()
        setAssignmentModeToFalse()
        clearSubMapRow()
        hideAssignManagementButtons()
        hideAssignmentTable()
        hideAssignmentsButton()
        streetDefaultStyle()
        placeDefaultStyle()
        i++
        $('#infoprev').prop('disabled', false)
        currentPlaceParse(features[i])
        if ((i + 1) >= numfeats) {
            $('#infonext').prop('disabled', true)
        }
    })
    $('#infoprev').on('click', function () {
        clearAssignmentLayer()
        clearInfoDiv()
        clearOtherInfoDiv()
        setAssignmentModeToFalse()
        clearSubMapRow()
        hideAssignManagementButtons()
        hideAssignmentTable()
        hideAssignmentsButton()
        streetDefaultStyle()
        placeDefaultStyle()

        i--
        if (i === 0) {
            $('#infoprev').prop('disabled', true);
            $('#infonext').prop('disabled', false);
        }
        currentPlaceParse(features[i])
    })

    currentPlaceParse(features[i])
}

export function getPlaceShapes(place_id){
    return {
        "url": process.env.appurl + "/api/getPlaceInfo/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "place_id": place_id
        }
    }
}