import $ from 'jquery'
import {
    createSingleSpotHourlyMeasuresTable,
    createSingleSpotMeasuresTable,
    createSummaryMeasuresTable
} from "./insightsInfoDisplays";
import Papa from 'papaparse'

export function getSingleSpotMeasures (){
    $('#insightsTableLoading').attr('hidden', false)
    let idlist =[]
    let papaconfig = {
        delimiter: "",
        newline: "\\r\\n",
        quoteChar:"'",
        skipEmptyLines:true
    }
    let papaList = Papa.parse($("#spot_id_list_multi").val(),papaconfig)["data"]
    $.each(papaList,function (index,value){
        idlist.push(value[0])
    })
    let targetseg
    let targetGeog
    if ($("#multi_0").is(":checked")){
        targetseg = 9330
    }
    else if ($("#multi_5").is(":checked")){
        targetseg = 2038
    }
    else if ($("#multi_18").is(":checked")){
        targetseg = 2035
    }
    else if ($("#multi_21").is(":checked")){
        targetseg = 2036
    }
    else{
        targetseg = $("#segment_id_multi").val()
    }
    if ($("#multi_local_dma").is(":checked")){
        targetGeog = ["local_dma"]
    }
    else{
        targetGeog =[]
        let geogPapaList = Papa.parse($("#target_geography_multi").val(),papaconfig)["data"]
        $.each(geogPapaList,function (index,value){
            targetGeog.push(value[0])
        })

    }
    // console.log(Papa.parse($("#spot_id_list_multi").val(),papaconfig))
    let body = {
        "id_list":idlist,
        "target_segment" : targetseg,
        "target_geography_list" : targetGeog,
        "hourly_measures": $("#hourly_single").is(":checked"),
        "period_days": parseInt($("#period_select_multi").val()),
        "status_type_name_list": ["*"],
        "fieldset":["frame_id",
            "representations",
            "media_name",
            "media_type.name",
            "status_type.name",
            "layouts.faces.spots.spot_id",
            "layouts.faces.spots.measures_list",
            "layouts.faces.spots.measures_time_calculated",
            "layouts.faces.spots.total_msg_impressions_list"],
        "base_segment":9330,
        "measures_required":true,
        "id_type":"spot_id",
    }
    let bodyString = JSON.stringify(body)

    let settings = {
        "url": process.env.appurl + "/api/getmeasuresbySpot",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data":{"search":bodyString}
    };
    // console.log(settings)
    $.ajax(settings).done(function (response){
        // console.log(response)
        let weeklydata = []
        let hourlydata =[]
        $.each(response['data']['inventory_summary']['inventory_items'],function (index,value){
            // console.log(value)
            let spots = value['layouts'][0]['faces'][0]['spots']
            weeklydata.push({
                    "frame_id":value["frame_id"],
                    "spot_id": spots[0]["spot_id"],
                    "media_name":value['media_name'],
                    "media_type":value["media_type"]["name"],
                    "parent_account": value['representations'][0]['account']["parent_account_name"],
                    "account": value['representations'][0]['account']["name"],
                    "base_segment": spots[0]['measures_list'][0]['base_segment'],
                    "eff_freq_avg": spots[0]['measures_list'][0]['eff_freq_avg'],
                    "eff_freq_min": spots[0]['measures_list'][0]['eff_freq_min'],
                    "eff_reach_net": spots[0]['measures_list'][0]['eff_reach_net'],
                    "eff_reach_pct": spots[0]['measures_list'][0]['eff_reach_pct'],
                    "freq_avg": spots[0]['measures_list'][0]['freq_avg'],
                    "imp": spots[0]['measures_list'][0]['imp'],
                    "imp_inmkt": spots[0]['measures_list'][0]['imp_inmkt'],
                    "imp_target": spots[0]['measures_list'][0]['imp_target'],
                    "imp_target_inmkt": spots[0]['measures_list'][0]['imp_target_inmkt'],
                    "index_comp_target": spots[0]['measures_list'][0]['index_comp_target'],
                    "market": spots[0]['measures_list'][0]['market'],
                    "measures_release": spots[0]['measures_list'][0]['measures_release'],
                    "pct_comp_imp_target": spots[0]['measures_list'][0]['pct_comp_imp_target'],
                    "pct_comp_imp_target_inmkt": spots[0]['measures_list'][0]['pct_comp_imp_target_inmkt'],
                    "pct_comp_pop_target_inmkt": spots[0]['measures_list'][0]['pct_comp_pop_target_inmkt'],
                    "pct_imp_inmkt": spots[0]['measures_list'][0]['pct_imp_inmkt'],
                    "pct_imp_target_inmkt": spots[0]['measures_list'][0]['pct_imp_target_inmkt'],
                    "period_days": spots[0]['measures_list'][0]['period_days'],
                    "pop_inmkt": spots[0]['measures_list'][0]['pop_inmkt'],
                    "pop_target_inmkt": spots[0]['measures_list'][0]['pop_target_inmkt'],
                    "reach_net": spots[0]['measures_list'][0]['reach_net'],
                    "reach_pct": spots[0]['measures_list'][0]['reach_pct'],
                    "target_geo": spots[0]['measures_list'][0]['target_geo'],
                    "target_segment": spots[0]['measures_list'][0]['target_segment'],
                    "measures_time_calculated": spots[0]["measures_time_calculated"]
                })
            if($("#hourly_single").is(":checked") === true){
                let spot_id = spots[0]["spot_id"]
                let monvals = spots[0]['total_msg_impressions_list'][0]['mon']
                let tuesvals = spots[0]['total_msg_impressions_list'][0]['tue']
                let wedvals = spots[0]['total_msg_impressions_list'][0]['wed']
                let thursvals = spots[0]['total_msg_impressions_list'][0]['thu']
                let frivals = spots[0]['total_msg_impressions_list'][0]['fri']
                let satvals = spots[0]['total_msg_impressions_list'][0]['sat']
                let sunvals = spots[0]['total_msg_impressions_list'][0]['sun']
                Object.assign(monvals,{spot_id:spot_id,day:"Monday"})
                Object.assign(tuesvals,{spot_id:spot_id,day:"Tuesday"})
                Object.assign(wedvals,{spot_id:spot_id,day:"Wednesday"})
                Object.assign(thursvals,{spot_id:spot_id,day:"Thursday"})
                Object.assign(frivals,{spot_id:spot_id,day:"Friday"})
                Object.assign(satvals,{spot_id:spot_id,day:"Saturday"})
                Object.assign(sunvals,{spot_id:spot_id,day:"Sunday"})
                hourlydata.push(monvals,tuesvals,wedvals,thursvals,frivals,satvals,sunvals)
            }
        })
        createSingleSpotMeasuresTable(weeklydata)
        if($("#hourly_single").is(":checked") === true){
            createSingleSpotHourlyMeasuresTable(hourlydata)
        }
        $('#insightsTableLoading').attr('hidden', true)
    })
}

export function getSummaryMeasures (){
    $('#insightsTableLoading').attr('hidden', false)
    let idlist =[]
    let papaconfig = {
        delimiter: "",
        newline: "\\r\\n",
        quoteChar:"'",
        skipEmptyLines:true
    }
    let papaList = Papa.parse($("#spot_id_list_sum").val(),papaconfig)["data"]
    $.each(papaList,function (index,value){
        idlist.push(value[0])
    })
    let targetseg
    let targetGeog
    if ($("#sum_0").is(":checked")){
        targetseg = 9330
    }
    else if ($("#sum_5").is(":checked")){
        targetseg = 2038
    }
    else if ($("#sum_18").is(":checked")){
        targetseg = 2035
    }
    else if ($("#sum_21").is(":checked")){
        targetseg = 2036
    }
    else{
        targetseg = $("#segment_id_sum").val()
    }
    if ($("#sum_local_dma").is(":checked")){
        targetGeog = ["local_dma"]
    }
    else{
        targetGeog =[]
        let geogPapaList = Papa.parse($("#target_geography_sum").val(),papaconfig)["data"]
        $.each(geogPapaList,function (index,value){
            targetGeog.push(value[0])
        })

    }
    // console.log(Papa.parse($("#spot_id_list_sum").val(),papaconfig))
    let body = {
        "id_list":idlist,
        "target_segment" : targetseg,
        "target_geography_list" : targetGeog,
        "period_days": parseInt($("#period_select_sum").val()),
        "status_type_name_list": ["*"],
        "base_segment":9330,
        "measures_required":true,
        "id_type":"spot_id",
    }
    let bodyString = JSON.stringify(body)

    let settings = {
        "url": process.env.appurl + "/api/getSummaryMeasures",
        "method": "post",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            "Geopath-User-Service-Account": localStorage.getItem('userEmail')
        },
        "data":{"search":bodyString}
    };
    // console.log(settings)
    $.ajax(settings).done(function (response){
        // console.log(response)
        createSummaryMeasuresTable(response['data']['summaries'])
        $('#insightsTableLoading').attr('hidden', true)
    })
}

export function tableDownload (table,name){
    table.download("csv",name +".csv")
}