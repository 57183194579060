import $ from 'jquery'
import {info} from "../supportFunctions/consts";
import {coordinateDisplay} from "../map_events/coordinateFunctions";

require('bootstrap/js/dist/modal')

export function prepPlaceInfoDisplay(data){
    // console.log('prepping info display')
    info.html(
        '<div id="place_info"></div>'
    )
    placeInfoDisplay(data)
}


export async function placeInfoDisplay(data) {
    coordinateDisplay([data['location']['longitude'],data['location']['latitude']])
    $("#place_info").html(
        '<table class="table table-striped table-bordered table-sm">'+
        '<tbody>' +
        '   <tr>' +
        '      <th scope="row">Place ID</th>' +
        '      <td>'+'<span id="currentPlaceID">' + data.id + '</span>'+'&nbsp<button class="clipboard" id="placeidcopy" data-clipboard-text="' + data.id + '"><i class="far fa-clipboard"></i></button></td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Place Name</th>' +
        '      <td>'+ data["place_name"]  +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Place Type</th>' +
        '      <td>'+ data['place_type']['name']+'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Entrances</th>' +
        '      <td>'+ data['entrances_cnt']+'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Floors</th>' +
        '      <td>'+ data['floors_cnt'] +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Gates</th>' +
        '      <td>'+ data['gates_cnt'] +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Concourses </th>' +
        '      <td>'+ data['concourse_cnt'] +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Platforms</th>' +
        '      <td>'+ data["platforms_cnt"] +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Street Address</th>' +
        '       <td>'+ data["street_address"] +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Sq Ft</th>' +
        '       <td>'+ data["area_sqft"] +'</td>' +
        '   </tr>'+
        '</tbody>'
    )
}