import $ from 'jquery'
import Tabulator from "../../node_modules/tabulator-tables/dist/js/tabulator"
import {tableDownload} from "./insightsFunctions";

let singleSpotMeasuresTable = null
let singleSpotHourlyMeasuresTable = null


export function createSingleSpotMeasuresTable (data){
    $("#measuresTableHeader").text('')
    $("#measuresTableHeader").text('Measures Table by Spot')
    $("#measuresTableHeader").attr('hidden', false)
    $("#measuresDownload").attr('hidden', false)
    $("#measuresDownloadButtonHolder").html('')
    $("#measuresDownloadButtonHolder").html('<button id="measuresDownload" type="button">Download Table</button>')
    // console.log(data)
    if(singleSpotMeasuresTable){
        singleSpotMeasuresTable.destroy()
    }
    $('#single_spot_measures_table').attr('hidden',false)
    $("#measuresDownload").click(function (){tableDownload(singleSpotMeasuresTable,"spotMeasuresTable")})
    // console.log(data)
    singleSpotMeasuresTable = new Tabulator ("#single_spot_measures_table", {
        selectable: 1,
        data: data,
        layout: "fitData",
        maxHeight:"35vh",
        columns:[
            {
                title: "Frame",
                field: "frame_id",
                frozen:true
            },
            {
                title: "Spot",
                field: "spot_id",
                frozen:true
            },
            {
                title: "Parent Account",
                field: "parent_account",
            },
            {
                title: "Account",
                field: "account",
            },
            {
                title: "Media Name",
                field: "media_name",
            },
            {
                title: "Media Type",
                field: "media_type",
            },
            {
                title: "Base Segment",
                field: "base_segment",
            },
            {
                title: "Target Segment",
                field: "target_segment",
            },
            {
                title: "Target Market",
                field: "market",
            },

            {
                title: "Period",
                field: "period_days",
            },
            {
                title: "Impressions",
                field: "imp",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Target Impressions",
                field: "imp_target",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "In Market Impressions",
                field: "imp_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Target In Market Impressions",
                field: "imp_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Index Comp Target",
                field: "index_comp_target",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                }
            },
            {
                title: "% Comp Target Impressions",
                field: "pct_comp_imp_target",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "% Comp Target In Market Impressions",
                field: "pct_comp_imp_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "% In Market Impressions",
                field: "pct_imp_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "% Target In Market Impressions",
                field: "pct_imp_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "Reach %",
                field: "reach_pct",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "Reach Net",
                field: "reach_net",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Effective Reach %",
                field: "eff_reach_pct",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "Effective Reach Net",
                field: "eff_reach_net",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Frequency Avg",
                field: "freq_avg",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Effective Frequency Min",
                field: "eff_freq_min",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Effective Frequency Avg",
                field: "eff_freq_avg",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Population In Market",
                field: "pop_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Population Target In Market",
                field: "pop_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            }
        ]
    })
}

export function createSingleSpotHourlyMeasuresTable(data){
    if(singleSpotHourlyMeasuresTable){
        singleSpotHourlyMeasuresTable.destroy()
    }
    // console.log(data)
    $("#hourlyMeasuresTableHeader").attr('hidden', false)
    $("#hourlyMeasuresSpan").attr('hidden', false)
    $('#single_spot_hourly_measures_table').attr('hidden',false)
    $("#hourlyDownloadButtonHolder").html('')
    $("#hourlyDownloadButtonHolder").html('<button id="hourlyDownload" type="button">Download Table</button>')
    $("#hourlyDownload").click(function (){tableDownload(singleSpotHourlyMeasuresTable,"hourlyMeasuresTable")})
    singleSpotHourlyMeasuresTable = new Tabulator ("#single_spot_hourly_measures_table", {
        selectable: 1,
        data: data,
        layout: "fitData",
        maxHeight:"35vh",
        columns:[
            {
                title: "Spot_ID",
                field: "spot_id",
                frozen:true
            },
            {
                title: "Day",
                field: "day",
                frozen:true
            },
            {
                title: "12 AM",
                field: "0",
            },
            {
                title: "1 AM",
                field: "1",
            },
            {
                title: "2 AM",
                field: "2",
            },
            {
                title: "3 AM",
                field: "3",
            },
            {
                title: "4 AM",
                field: "4",
            },
            {
                title: "5 AM",
                field: "5",
            },
            {
                title: "6 AM",
                field: "6",
            },
            {
                title: "7 AM",
                field: "7",
            },
            {
                title: "8 AM",
                field: "8",
            },
            {
                title: "9 AM",
                field: "9",
            },
            {
                title: "10 AM",
                field: "10",
            },
            {
                title: "11 AM",
                field: "11",
            },
            {
                title: "12 PM",
                field: "12",
            },
            {
                title: "1 PM",
                field: "13",
            },
            {
                title: "2 PM",
                field: "14",
            },
            {
                title: "3 PM",
                field: "15",
            },
            {
                title: "4 PM",
                field: "16",
            },
            {
                title: "5 PM",
                field: "17",
            },
            {
                title: "6 PM",
                field: "18",
            },
            {
                title: "7 PM",
                field: "19",
            },
            {
                title: "8 PM",
                field: "20",
            },
            {
                title: "9 PM",
                field: "21",
            },
            {
                title: "10 PM",
                field: "22",
            },
            {
                title: "11 PM",
                field: "23",
            }
        ],

    })
}

// the summary measures table uses the same name and spot as the single spot measures table
export function createSummaryMeasuresTable(data){
    $("#measuresTableHeader").text('')
    $("#measuresTableHeader").text('Summary Measures Table')
    $("#measuresTableHeader").attr('hidden', false)
    $("#measuresDownload").attr('hidden', false)
    $("#measuresDownloadButtonHolder").html('')
    $("#measuresDownloadButtonHolder").html('<button id="measuresDownload" type="button">Download Table</button>')
    $("#hourlyMeasuresTableHeader").attr('hidden', true)
    $("#hourlyMeasuresSpan").attr('hidden', true)
    $('#single_spot_hourly_measures_table').attr('hidden',true)
    $("#hourlyDownloadButtonHolder").html('')
    if(singleSpotHourlyMeasuresTable){
        singleSpotHourlyMeasuresTable.destroy()
    }
    if(singleSpotMeasuresTable){
        singleSpotMeasuresTable.destroy()
    }
    // console.log(data)
    $('#single_spot_measures_table').attr('hidden',false)
    $("#measuresDownload").click(function (){tableDownload(singleSpotMeasuresTable,"summaryMeasuresTable")})
    singleSpotMeasuresTable = new Tabulator ("#single_spot_measures_table", {
        selectable: 1,
        data: data,
        layout: "fitData",
        maxHeight:"55vh",
        columns:[
            {
                title: "Frame Count",
                field: "frames"
            },
            {
                title: "Spot Count",
                field: "spots"
            },
            {
                title: "Base Segment",
                field: "base_segment",
            },
            {
                title: "Target Segment",
                field: "target_segment",
            },
            {
                title: "Target Market",
                field: "market",
            },

            {
                title: "Period",
                field: "period_days",
            },
            {
                title: "Impressions",
                field: "imp",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Target Impressions",
                field: "imp_target",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "In Market Impressions",
                field: "imp_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Target In Market Impressions",
                field: "imp_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Index Comp Target",
                field: "index_comp_target",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                }
            },
            {
                title: "% Comp Target Impressions",
                field: "pct_comp_imp_target",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "% Comp Target In Market Impressions",
                field: "pct_comp_imp_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "% In Market Impressions",
                field: "pct_imp_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "% Target In Market Impressions",
                field: "pct_imp_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "Reach %",
                field: "reach_pct",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "Reach Net",
                field: "reach_net",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Effective Reach %",
                field: "eff_reach_pct",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2,
                    symbol:"%",
                    symbolAfter:"p",
                }
            },
            {
                title: "Effective Reach Net",
                field: "eff_reach_net",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Frequency Avg",
                field: "freq_avg",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Effective Frequency Min",
                field: "eff_freq_min",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Effective Frequency Avg",
                field: "eff_freq_avg",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:2
                }
            },
            {
                title: "Population In Market",
                field: "pop_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Population Target In Market",
                field: "pop_target_inmkt",
                formatter:"money", formatterParams:{
                    decimal:".",
                    thousand:",",
                    precision:0,
                }
            }
        ]
    })


}