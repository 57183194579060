import {gp_inv_vt_ly_min,} from "../layers/vectorTileLayers";
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";
import Icon from "ol/style/Icon";
import {clearRotateLayer, filteredInventoryLayer, rotateLayer, rotateSource} from "../layers/activeLayers";
import gp_frame_selected from "../assets/gp_frame_selected.svg"
import {transform} from 'ol/proj';
import {degrees_to_radians} from "../supportFunctions/mathFunctions";
import {GeoJSON} from 'ol/format';
import $ from "jquery";

const roadside = new Style({
    image: new CircleStyle({
        radius: 5,
        fill: new Fill({
            color: [145,191,219, 1]
        }),
        stroke: new Stroke({
            color: [55, 56, 58, 1]
        })
    })
})

const placebased = new Style({
    image: new CircleStyle({
        radius: 5,
        fill: new Fill({
            color: [136,86,167, 1]
        }),
        stroke: new Stroke({
            color: [55, 56, 58, 1]
        })
    })
})

const fleet = new Style({
    image: new CircleStyle({
        radius: 5,
        fill: new Fill({
            color: [49,163,84, 1]
        }),
        stroke: new Stroke({
            color: [55, 56, 58, 1]
        })
    })
})

//red semi circle style rotated to facing
export function selectedStyle(rotateval) {
    return new Style ({
        image: new Icon({
            crossOrigin: 'anonymous',
            src: gp_frame_selected,
            rotation: rotateval
        })
    })
}

// set style of a selected piece of inventory and add it to the rotate layer so it can be edited.
export function selectedInventory(selectedFrameID){
    gp_inv_vt_ly_min.setStyle(function (feature) {
        //get properties of inventory features in view
        const properties = feature.getProperties();
        //if they match the provided frame id, proceed with that one feature
        if (properties.frame_id === selectedFrameID) {
            //clear anything in the rotate layer
            clearRotateLayer()
            //get data ready to be put in geojson
            let newcoords = transform([parseFloat(properties.longitude), parseFloat(properties.latitude)],'EPSG:4326', 'EPSG:3857');
            let rotateval;
            if ('orientation' in properties){
                rotateval = properties.orientation
            }
            // set default orientation to 0 and notify
            else {
                rotateval = 0;
                $("#submaprow").innerHTML =('<h4 style="color: red; text-decoration:underline ">Default Orientation Used, please set correct orientation or mark as failed audit</h4>')
            }
            //create geojson from provided data to read as a feature
            const selectedGeoJSON ="{\"type\": \"FeatureCollection\",\"crs\": {\"type\": \"name\", \"properties\": {\"name\": \"EPSG:3857\"}}, \"features\": [{\"type\": \"Feature\", \"geometry\": {\"type\": \"Point\", \"coordinates\": ["+newcoords+"]}, \"properties\": {\"orientation\": "+rotateval+",\"frame_id\": "+selectedFrameID+",\"longitude\": "+properties.longitude+",\"latitude\": "+properties.latitude+"}}]}";
            const selectedGeoJSONFeatures = new GeoJSON().readFeatures(selectedGeoJSON);
            //add geojson feature to the rotate layer vector source and set style
            rotateSource.addFeatures(selectedGeoJSONFeatures);
            rotateLayer.setStyle(new Style ({
                image: new Icon({
                    crossOrigin: 'anonymous',
                    src: gp_frame_selected,
                    rotation: degrees_to_radians(rotateval)
                })
            }));
        }
        // set circle based on class if not a match
        else {
            if (feature.get('classification_type_sk')===1){
                return roadside
            }
            if (feature.get('classification_type_sk')===4){
                return placebased
            }
            else {
                return fleet
            }
        }
    });
}

export function selectedFilteredInventory(selectedFrameID) {
    filteredInventoryLayer.setStyle(function (feature) {
        //get properties of inventory features in view
        const properties = feature.getProperties();
        //if they match the provided frame id, proceed with that one feature
        if (properties.frame_id === selectedFrameID) {
            //clear anything in the rotate layer
            clearRotateLayer()
            //get data ready to be put in geojson
            let newcoords = transform([parseFloat(properties.longitude), parseFloat(properties.latitude)],'EPSG:4326', 'EPSG:3857');
            let rotateval;
            if ('orientation' in properties){
                rotateval = properties.orientation
            }
            // set default orientation to 0 and notify
            else {
                rotateval = 0;
                $("#submaprow").html('<h4 style="color: red; text-decoration:underline ">Default Orientation Used, please set correct orientation or mark as failed audit</h4>')
            }
            //create geojson from provided data to read as a feature
            const selectedGeoJSON ="{\"type\": \"FeatureCollection\",\"crs\": {\"type\": \"name\", \"properties\": {\"name\": \"EPSG:3857\"}}, \"features\": [{\"type\": \"Feature\", \"geometry\": {\"type\": \"Point\", \"coordinates\": ["+newcoords+"]}, \"properties\": {\"orientation\": "+rotateval+",\"frame_id\": "+selectedFrameID+",\"longitude\": "+properties.longitude+",\"latitude\": "+properties.latitude+"}}]}";
            const selectedGeoJSONFeatures = new GeoJSON().readFeatures(selectedGeoJSON);
            //add geojson feature to the rotate layer vector source and set style
            rotateSource.addFeatures(selectedGeoJSONFeatures);
            rotateLayer.setStyle(new Style ({
                image: new Icon({
                    crossOrigin: 'anonymous',
                    src: gp_frame_selected,
                    rotation: degrees_to_radians(rotateval)
                })
            }));
        }
            // else if (operatorFilterStatus === 'T') {
            //     operatorFilter()
            // }
        // set regular circle if not a match
        else {
            return new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: [255,255,191, 1]
                    }),
                    stroke: new Stroke({
                        color: [55, 56, 58, 1]
                    })
                })
            })
        }
    });

}

export const filteredInventoryStyle = new Style({
    image: new CircleStyle({
        radius: 5,
        fill: new Fill({
            color: [255,255,191, 1]
        }),
        stroke: new Stroke({
            color: [55, 56, 58, 1]
        })
    })
})

// create coordinate search icon for mapping when coordinate searching
export const coordinateSearchIcon =
    new Style({
        text: new Text({
            text: '\uf3c5',
            font: '900 25px "Font Awesome 5 Free',
            fill: new Fill({
                color: [0, 131, 154]
            }),
            stroke: new Stroke({
                color: [0, 131, 154]
            })

        })
    })

export function inventoryClassStyler(feature,resolution){
    if (feature.get('classification_type_sk')===1){
        return roadside
    }
    if (feature.get('classification_type_sk')===4){
        return placebased
    }
    else {
        return fleet
    }
}