import $ from "jquery"
import {StringIdGenerator} from "../supportFunctions/consts";


//!!!!!! IMPORTANT NOTE FOR API CREATION !!!!!!!
// FRAME MUST HAVE STATUS AND TIME MUST BE TO THE SECOND ie: 23:59:59
export function createInventoryFromForm() {
    let frameheightinches
    let framewidthinches
    let faceheightinches
    let facewidthinches
    let orientationdegrees
    let spotSOV
    let constructiondate
    if ($("#inputFrameHeightFeet").val()){
        // console.log('ht ft engaged')
        frameheightinches = (parseInt($("#inputFrameHeightFeet").val())*12) + parseInt($("#inputFrameHeightInches").val())
    }
    else{
        frameheightinches = parseInt($("#inputFrameHeightInches").val())
    }
    if ($("#inputFrameWidthFeet").val()){
        // console.log('wd ft engaged')
        framewidthinches = (parseInt($("#inputFrameWidthFeet").val())*12) + parseInt($("#inputFrameWidthInches").val())
    }
    else{
        framewidthinches = parseInt($("#inputFrameWidthInches").val())
    }
    if ($("#inputFaceHeightFeet").val()){
        // console.log('face ht ft engaged')
        faceheightinches = (parseInt($("#inputFaceHeightFeet").val())*12) + parseInt($("#inputFaceHeightInches").val())
    }
    else{
        faceheightinches = parseInt($("#inputFaceHeightInches").val())
    }
    if ($("#inputFaceWidthFeet").val()){
        // console.log('face wd ft engaged')
        facewidthinches = (parseInt($("#inputFaceWidthFeet").val())*12) + parseInt($("#inputFaceWidthInches").val())
    }
    else{
        facewidthinches = parseInt($("#inputFaceWidthInches").val())
    }
    if ($("#inputorientationDirDropDown").is(":visible")){
        let text = $("#inputorientationDirDropDown").select2('data')[0]['text']
        if (text === 'N'){
            orientationdegrees = 0
        }
        else if (text === 'NE') {
            orientationdegrees = 45
        }
        else if (text === 'E') {
            orientationdegrees = 90
        }
        else if (text === 'SE') {
            orientationdegrees = 135
        }
        else if (text === 'S') {
            orientationdegrees = 180
        }
        else if (text === 'SW') {
            orientationdegrees = 225
        }
        else if (text === 'W') {
            orientationdegrees = 270
        }
        else if (text === 'NW') {
            orientationdegrees = 315
        }
        else {
            orientationdegrees = 0
        }
    }
    else{
        orientationdegrees = parseFloat($("#inputFrameOrientation").val())
    }
    // console.log(orientationdegrees)
    if ($("#inputSpotCount").val()){
        spotSOV = 1/$("#inputSpotCount").val()
    }
    else{
        spotSOV = 1
    }
    if ($("#inputConstructionDate").val()){
        constructiondate= $("#inputConstructionDate").val()+"T00:00:00.000Z"
        // console.log(constructiondate)
    }
    else {
        constructiondate = null
    }
    let json = {
        "plant_frame_id": $("#inputOperatorFrameID").val(),
        "illumination_start_time": $("#inputFrameIllStart").val(),
        "illumination_end_time": $("#inputFrameIllEnd").val(),
        "max_height": frameheightinches,
        "max_width": framewidthinches,
        "max_pixel_height": $("#inputFramePixelHeight").val(),
        "max_pixel_width": $("#inputFramePixelWidth").val(),
        "construction_date": constructiondate,
        "description": $("#inputFrameDescription").val(),
        "full_motion": $("#inputFrameFullMotionTF").val(),
        "partial_motion": $("#inputFramePartialMotionTF").val(),
        "rotating": $("#inputFrameRotatingTF").val(),
        "interactive": $("#inputFrameInteractiveTF").val(),
        "audio": $("#inputFrameAudioTF").val(),
        "digital": $("#inputFrameDigitalTF").val(),
        "media_name": $("#inputFrameMediaName").val(),
        "engagement": $("#inputFrameEngagement").val(),
        "classification_type": {
            "id": $('#inputclassTypeDropDown').select2('data')[0]['id']
        },
        "construction": {
            "name": $("#inputConstructionName").val(),
            "construction_type": {
                "id": $('#inputconstructionTypeDropDown').select2('data')[0]['id']
            },
            "location": {
                "orientation": orientationdegrees,
                "primary_read": $("#inputFramePrimaryRead").val(),
                "primary_artery": $("#inputFramePrimaryArtery").val(),
                "latitude": $("#inputFrameLatitude").val(),
                "longitude": $("#inputFrameLongitude").val(),
            },
        },
        "illumination_type": {
            "id": $('#inputillTypeDropDown').select2('data')[0]['id']
        },
        "location": {
            "orientation": orientationdegrees,
            "primary_read": $("#inputFramePrimaryRead").val(),
            "primary_artery": $("#inputFramePrimaryArtery").val(),
            "latitude": $("#inputFrameLatitude").val(),
            "longitude": $("#inputFrameLongitude").val(),
            "place_type":{
                "id": $("#inputPlaceTypeDropDown").select2('data')[0]['id']
            }
        },
        "placement_type":{
            "id": $("#inputPlacementTypeDropDown").select2('data')[0]['id']
        },
        "media_type": {
            "id": $('#inputmediaTypeDropDown').select2('data')[0]['id']
        },
        "status_type":{
            "id":20
        },
        "layouts": [
            {
                "full_motion": $("#inputLayoutFullMotionTF").val(),
                "partial_motion": $("#inputLayoutPartialMotionTF").val(),
                "rotating": $("#inputLayoutRotatingTF").val(),
                "interactive": $("#inputLayoutInteractiveTF").val(),
                "audio": $("#inputLayoutAudioTF").val(),
                "share_of_voice": $("#inputLayoutSOV").val(),
                "faces": [
                    {
                        "height":faceheightinches,
                        "width": facewidthinches,
                        "pixel_height": $("#inputFacePixelHeight").val(),
                        "pixel_width": $("#inputFacePixelWidth").val(),
                        "top_left_pixel_x": $("#inputFaceTopPixelXHeight").val(),
                        "top_left_pixel_y": $("#inputFaceTopPixelYWidth").val(),
                        "spots_in_rotation": $("#inputSpotCount").val(),
                        "spots": []
                    }
                ]
            }
        ]
    }
    if (parseInt($("#inputSpotCount").val())>1){
        let i = parseInt($("#inputSpotCount").val())
        let j = 1
        const spotalpha = new StringIdGenerator()
        let divider
        function spotadder(){
            if (j <= i){
                let parentName = $('#inputParentAccountDropDown').select2('data')[0]['text']
                // console.log(parentName)
                if (parentName === 'OUTFRONT'){
                    divider = " "
                }
                else{
                    divider = "-"
                }
                json['layouts'][0]['faces'][0]['spots'].push(
                    {
                        "plant_spot_id": $("#inputOperatorSpotID").val()+divider+spotalpha.next(),
                        "publisher_unique_id": $("#inputPublisherUniqueID").val(),
                        "length": $("#inputSpotLen").val(),
                        "full_motion": $("#inputSpotFullMotionTF").val(),
                        "partial_motion": $("#inputSpotPartialMotionTF").val(),
                        "rotating": $("#inputSpotRotatingTF").val(),
                        "interactive": $("#inputSpotInteractiveTF").val(),
                        "audio": $("#inputSpotAudioTF").val(),
                        "share_of_voice": spotSOV
                    }
                )
                j++
                spotadder()
            }
        }
        spotadder()
    }
    else {
        json['layouts'][0]['faces'][0]['spots'].push(
            {
                "plant_spot_id": $("#inputOperatorSpotID").val(),
                "publisher_unique_id": $("#inputPublisherUniqueID").val(),
                "length": $("#inputSpotLen").val(),
                "full_motion": $("#inputSpotFullMotionTF").val(),
                "partial_motion": $("#inputSpotPartialMotionTF").val(),
                "rotating": $("#inputSpotRotatingTF").val(),
                "interactive": $("#inputSpotInteractiveTF").val(),
                "audio": $("#inputSpotAudioTF").val(),
                "share_of_voice": $("#inputSpotSOV").val()
            }
        )
    }
    let stringifieddata = JSON.stringify(json)
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "/api/createInventoryRecord",
        "method": "post",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            'Geopath-User-Service-Account': localStorage.getItem('userEmail')
        },
        "data": {"frameObj":stringifieddata}
    }
    // console.log(json)
    $.ajax(settings).done(function (response) {
        // console.log(response)
        if (response[0]['status'] === 200){
            let responsedata = response[0]['data']
            let returnedids = {
                "frame_id": responsedata['id'],
                "construction_id": responsedata['construction']['id'],
                "location_id": responsedata['location']['id'],
                "layout_ids":[],
                "face_ids":[],
                "spot_ids":[],
            }
            $.each(responsedata['layouts'],function (layoutindex,layoutvalue){
                returnedids['layout_ids'].push(layoutvalue['id'])
                $.each(responsedata['layouts'][layoutindex]['faces'], function (faceindex,facevalue){
                    returnedids['face_ids'].push(facevalue['id'])
                    $.each(responsedata['layouts'][layoutindex]['faces'][faceindex]['spots'], function (spotindex,spotvalue){
                        returnedids['spot_ids'].push(spotvalue['id'])
                    })
                })
            })
            // console.log(returnedids);
            createInventoryReps(returnedids)
            $("#createdIDTable").html('<tr>'+
            '<th><label>Frame</label></th>'+
            '<td>'+returnedids['frame_id']+'&nbsp<button class="clipboard" id="newframeidcopy" data-clipboard-text="' + returnedids['frame_id'] + '"><i class="far fa-clipboard"></i></button></td></tr>')
            $.each(returnedids['spot_ids'], function (index,value){
                $("#createdIDTable").append('<tr>'+
                    '<th><label>Spot</label></th>'+
                    '<td>'+value+'&nbsp<button class="clipboard" id="newframeidcopy" data-clipboard-text="' + value + '"><i class="far fa-clipboard"></i></button></td></tr>')
            })

        }
        else {
            $("#createdIDTable").html('<tr><td>CREATE FAILED, Please Try Again</td></tr>')
        }


    })
}



export function createXrefSettings(invid,type){
    let posturl
    let reptypeid
    let reptypetext = $('#inputRepTypeDropDown').select2('data')[0]['text']
    if (type === 'frame'){
        posturl = 'management/inventory/'+invid+'/representations'
    }
    else if (type === 'construction'){
        posturl = 'management/inventory/constructions/'+invid+'/representations'
    }
    else if (type === 'layout'){
        posturl = 'management/inventory/layouts/'+invid+'/representations'
    }
    else if (type === 'face'){
        posturl = 'management/inventory/faces/'+invid+'/representations'
    }
    else if (type === 'spot'){
        posturl = 'management/inventory/spots/'+invid+'/representations'
    }
    if (reptypetext === 'Own'){
        reptypeid = 1
    }
    else if (reptypetext === 'Manage'){
        reptypeid = 2
    }
    else if (reptypetext === 'Transact'){
        reptypeid = 3
    }
    else if (reptypetext === 'Schedule'){
        reptypeid = 4
    }
    return {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "/api/createXref",
        "method": "get",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token'),
            'Geopath-User-Service-Account': localStorage.getItem('userEmail')
        },
        "data": {
            "inv_id": invid,
            "rep_id":$('#inputRepTypeDropDown').select2('data')[0]['id'],
            "account_id":$('#inputAccountDropDown').select2('data')[0]['id'],
            "rep_type_id": reptypeid,
            "posturl":posturl
        }
    }
}

export function createInventoryReps(ids){
    let i = 0
    $.ajax(createXrefSettings(ids['frame_id'],'frame')).done(function (response) {
        i++
    })
    $.ajax(createXrefSettings(ids['construction_id'],'construction')).done(function (response) {
        i++
    })
    $.each(ids['layout_ids'],function (index,value){
        $.ajax(createXrefSettings(value,'layout')).done(function (response) {
            i++
        })
    })
    $.each(ids['face_ids'],function (index,value){
        $.ajax(createXrefSettings(value,'face')).done(function (response) {
            i++
        })
    })
    $.each(ids['spot_ids'],function (index,value){
        $.ajax(createXrefSettings(value,'spot')).done(function (response) {
            i++
        })
    })



}