import $ from "jquery";
import {selectedCstationStyle, selectedSegmentStyle} from "../map_styles/lineStyle";
import {assignmentlayer} from "../layers/activeLayers";
import {directionArray, trafficAgg} from "./lineFunctions";
import {totLineInfo} from "../infoDisplays/totalLineInfo";
import {cstationLineInfo, indLineInfo} from "../infoDisplays/individualLineInfo";
import {GeoJSON, WKT} from "ol/format";
import {otherinfo} from "../supportFunctions/consts";
import {checkAssignmentMode} from "../assignments/assignmentActions";

//create function to handle individual line parsing
function individualLineInfo(features,network_year) {
    otherinfo.html('')
    //set header for info box since additional info will be in loop
    otherinfo.html('<h4> Individual Segment Info</h4>')
    $.each(features,function (index,value) {
        //get geometries of the current segment
        let linewktGeom = new WKT().writeFeature(value);
        let linegeojsonObject = new GeoJSON().writeFeature(value);
        //get all properties about the current segment
        let props = value['values_']
        //get information about the opposite segment of the current segment
        let oppseg;
        let oppseggeom;
        let oppseglanes;
        if (props['segment_r'] === "-1") {
            oppseg = "-1"
        }
        else {
            if(network_year === 2016){
                oppseg = props['segment_r'] + "_" + props['region'];
            }
            else if (network_year === 2021){
                oppseg = props['segment_r']
            }
            if (index === 0) {
                oppseggeom = new WKT().writeFeature(features[1])
                oppseglanes = features[1]['values_']['lanes']
            }
            else if (index === 1) {
                oppseggeom = new WKT().writeFeature(features[0])
                oppseglanes = features[0]['values_']['lanes']
            }
        }
        //go to display control for individual segments
        indLineInfo(props,linegeojsonObject,linewktGeom,oppseg,oppseglanes,oppseggeom)

    })
    // add 'add to assignment' button when in assignment mode
    if (checkAssignmentMode()) {
        $(".hiddentablerow").prop("hidden",false)
        // console.log($(this).attr('id'))
        otherinfo.on('click',"[name='segbutton']", function () {
            document.getElementById('segListForm').value = $(this).attr('id');
            document.getElementById('selectedLineWKT').value = $(this).attr('data-linewkt');
            document.getElementById('segLanes').value = $(this).attr('data-segLanes');
            document.getElementById('selectedLineOppseg').value = $(this).attr('data-oppseg');
            document.getElementById('selectedLineOppseggeom').value = $(this).attr('data-oppsegwkt');
            document.getElementById('oppSegLanes').value = $(this).attr('data-oppsegLanes');
        })
    }
}

export function getLineShapes(segment_id,network_year){
    return {
        "url": process.env.appurl + "/api/getsegmentShapes/",
        "method": "get",
        "content-type": "application/json",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {
            "segment": segment_id,
            "network_year": network_year
        }
    }

}

export function lineHandler(features) {
    let numfeats = features['length']
    assignmentlayer.getSource().clear();
    //check if there are too many segments selected
    if (numfeats <= 2) {
        //check if it's the HERE Layer
        if (features[0]['values_']['layer'] === 'sl_national_network.sl_2016r3_new_adjusted') {
            //get segment id
            let idProp = features[0]['values_']['segment_reg'];
            //highlight on map
            selectedSegmentStyle(idProp,2016)
            //get info for all lines selected. link and street name just take the first since all lines are on top of each other
            let trafficAggs = trafficAgg(features)
            let directionAggs = directionArray(features)
            let link_id = features[0]['values_']['link_id']
            let st_name = features[0]['values_']['st_name']
            //display the total link info in the top info box
            totLineInfo(numfeats,trafficAggs,directionAggs,link_id,st_name)
            //take the features and parse individually
            individualLineInfo(features, 2016)
        }
        else if (features[0]['values_']['layer'] === 'sl_national_network.sl_2021r1' ){
            // console.log(features)
            let idProp = features[0]['values_']['segment'];
            //highlight on map
            selectedSegmentStyle(idProp,2021)
            //get info for all lines selected. link and street name just take the first since all lines are on top of each other
            let trafficAggs = trafficAgg(features)
            let link_id = idProp.split("_",1)
            let directionAggs = directionArray(features)
            let st_name = features[0]['values_']['st_name']
            //display the total link info in the top info box
            totLineInfo(numfeats,trafficAggs,directionAggs,link_id,st_name)
            //take the features and parse individually
            individualLineInfo(features,2021)
        }
        else if (features[0]['values_']['layer'] === 'sl_national_network.tab_cstation_traffic') {
            let idprop = features[0]['values_']['cstationid']
            selectedCstationStyle(idprop)
            let props = features[0]['values_']
            cstationLineInfo(props)
        }
    }
    // if there's more than two segments, display the individual info for them all
    else {
        if (features[0]['values_']['layer'] === 'sl_national_network.sl_2016r3_new_adjusted') {
            let idProp = features[0]['values_']['segment'];
            individualLineInfo(features,2016)
            //highlight only the first one on map since they all overlay each other anyway
            selectedSegmentStyle(idProp,2016)
            //add an alert in the map area
            $("#mapUtilsHeader").html('<h3> ! More than 2 segments Selected !<br></h3>')
        }
        if (features[0]['values_']['layer'] === 'sl_national_network.sl_2021r1') {
            let idProp = features[0]['values_']['segment'];
            individualLineInfo(features,2021)
            //highlight only the first one on map since they all overlay each other anyway (not necessarily true)
            selectedSegmentStyle(idProp,2021)
            //add an alert in the map area
            $("#mapUtilsHeader").html('<h3> ! More than 2 segments Selected !<br></h3>')
        }
    }
}




