import $ from "jquery"
import {
    classificationSelectVals,
    constructionSelectVals,
    illuminationSelectVals,
    mediaSelectVals,
    placementSelectVals,
    placeTypeSelectVals,
    representationTypeSelectVals
} from "../supportFunctions/apiTypes";
import {accounts, parent_accounts} from "../supportFunctions/accounts";

////// THIS IS CURRENTLY NOT IN USE. IN PREP FOR FUTURE USE

export function createEditInventoryForm(d){
    // console.log(d)
    $("#InventoryFormPlaceHolder").html('');
    $("#InventoryFormPlaceHolder").html('<div class="row">\n' +
        '    <div class="col-6">\n' +
        '        <form class="needs-validation" id="frameform">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Frame Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '\n' +
        '                    <th><label for="editOperatorFrameID">Operator Frame ID</label><span class="requiredForm">*</span></th>\n' +
        '                    <td class="form-group"><input required aria-required="true" type="text" class="form-control" id="editOperatorFrameID"\n' +
        '                                                  placeholder="Operator\'s Unique frame identifier -  no duplicates within a account">\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter an Operator ID\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editConstructionName">Construction Name</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="editConstructionName"\n' +
        '                               placeholder="ID of the structure the frame is on">\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editConstructionDate">Construction Date</label></th>\n' +
        '                    <td><input type="date" class="form-control" id="editConstructionDate"\n' +
        '                               placeholder="Date the frame was constructed/installed">\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editclassTypeDropDown">Classification Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="editclassTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please select a classicification.\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="editFrameHeightInches">Frame Height</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="editheightFrameOffToggleDiv"><i title="Toggle Feet edit" id="editheightFrameOffToggle"\n' +
        '                                                                      class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="editheightFrameOnToggleDiv" hidden><i title="Toggle Feet edit" id="editheightFrameOnToggle"\n' +
        '                                                                            class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="editheightFrameFtDiv" hidden>\n' +
        '                                <input type="number" class="form-control" id="editFrameHeightFeet"\n' +
        '                                       placeholder="Height Feet">\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="editheightFrameInDiv">\n' +
        '                                <input required aria-required="true" type="number" class="form-control"\n' +
        '                                       id="editFrameHeightInches"\n' +
        '                                       placeholder="Height Inches">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter height in inches as a number\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="editFrameWidthInches">Frame Width</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="editwidthFrameOffToggleDiv"><i title="Toggle Feet edit" id="editwidthFrameOffToggle"\n' +
        '                                                                     class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="editwidthFrameOnToggleDiv" hidden><i title="Toggle Feet edit" id="editwidthFrameOnToggle"\n' +
        '                                                                           class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="editwidthFrameFtDiv" hidden>\n' +
        '                                <input type="number" class="form-control" id="editFrameWidthFeet"\n' +
        '                                       placeholder="Width Feet">\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="editwidthFrameInDiv">\n' +
        '                                <input required aria-required="true" type="number" class="form-control"\n' +
        '                                       id="editFrameWidthInches"\n' +
        '                                       placeholder="Width Inches">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter width in inches as a number.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFramePixelHeight">Pixel Height</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="editFramePixelHeight"\n' +
        '                               placeholder="For Digital Only Screen Height in Pixels"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFramePixelWidth">Pixel Width</label></th>\n' +
        '                    <td><input type="number" class="form-control" id="editFramePixelWidth"\n' +
        '                               placeholder="For Digital Only Screen Width in Pixels"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameIllStart">Illumination Time</label><span class="requiredForm">*</span></th>\n' +
        '                    <td>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div id="illStarDiv">\n' +
        '                                <input required aria-required="true" type="time" class="form-control"\n' +
        '                                       id="editFrameIllStart"\n' +
        '                                       placeholder="Start Time"\n' +
        '                                       step="2" value="00:00:00">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter time as numbers to the second.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            &nbsp&nbsp\n' +
        '                            <div id="illEndDiv">\n' +
        '                                <input required aria-required="true" type="time" class="form-control"\n' +
        '                                       id="editFrameIllEnd" placeholder="End Time" step="2" value="11:59:59">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter time as numbers to the second.\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editillTypeDropDown">Illumination Type </label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="editillTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter an illumination type\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameMediaName">Frame Media Name</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="editFrameMediaName"\n' +
        '                               placeholder="Media name used by operator"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editconstructionTypeDropDown">Construction Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="editconstructionTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a construction type\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editmediaTypeDropDown">Media Type</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
        '                                id="editmediaTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a Media Type\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameDigitalTF">Frame Digital</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="editFrameDigitalTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameRotatingTF">Frame Rotating</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="editFrameRotatingTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameFullMotionTF">Frame Full Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="editFrameFullMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFramePartialMotionTF">Frame Partial Motion</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="editFramePartialMotionTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameInteractiveTF">Frame Interactive</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="editFrameInteractiveTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameAudioTF">Frame Audio</label></th>\n' +
        '                    <td><select\n' +
        '                            class="form-control select2" id="editFrameAudioTF">\n' +
        '                        <option>false</option>\n' +
        '                        <option>true</option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameDescription">Frame Description</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="editFrameDescription"></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '        <form class="needs-validation">\n' +
        '            <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '                <tbody>\n' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Location Information</h5></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameLatitude">Latitude</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><input required aria-required="true" type="number" class="form-control" id="editFrameLatitude" min=24 max=50 step=any>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a latitude as a postive decimal number between 24 and 50\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFrameLongitude">Longitude</label><span class="requiredForm">*</span></th>\n' +
        '                    <td><input required aria-required="true" type="number" class="form-control"\n' +
        '                               id="editFrameLongitude" min=-125 max=-65 step=any>\n' +
        '                        <div class="invalid-feedback">\n' +
        '                            Please enter a longtidue as a negative decimal number between -65 and -125\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th>\n' +
        '                        <div style="display: flex">\n' +
        '                            <div><label for="editFrameOrientation">Orientation</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
        '                            <div id="editorientationDirOffToggleDiv"><i title="Toggle Orientation Direction"\n' +
        '                                                                         id="orientationDirOffToggle"\n' +
        '                                                                         class="fas fa-toggle-off"></i></div>\n' +
        '                            <div id="editorientationDirOnToggleDiv" hidden><i title="Toggle Orientation Direction"\n' +
        '                                                                               id="orientationDirOnToggle"\n' +
        '                                                                               class="fas fa-toggle-on"></i></div>\n' +
        '                        </div>\n' +
        '                    </th>\n' +
        '                    <td>\n' +
        '                        <div>\n' +
        '                            <div id="editorientationDegDiv">\n' +
        '                                <input required type="number" class="form-control" id="editFrameOrientation" min="0" max="359">\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please enter orientation as a whole number between 0 and 359\n' +
        '                                </div>\n' +
        '                            </div>\n' +
        '                            <div id="editorientationDirDiv" hidden>\n' +
        '                                <select class="form-control select2" id="editorientationDirDropDown">\n' +
        '                                    <option></option>\n' +
        '                                    <option>N</option>\n' +
        '                                    <option>NE</option>\n' +
        '                                    <option>E</option>\n' +
        '                                    <option>SE</option>\n' +
        '                                    <option>S</option>\n' +
        '                                    <option>SW</option>\n' +
        '                                    <option>W</option>\n' +
        '                                    <option>NW</option>\n' +
        '                                </select>\n' +
        '                                <div class="invalid-feedback">\n' +
        '                                    Please select an orientation from the list\n' +
        '                                </div>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
        '                    </td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFramePrimaryArtery">Primary Artery</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="editFramePrimaryArtery"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editFramePrimaryRead">Primary Read</label></th>\n' +
        '                    <td><input type="text" class="form-control" id="editFramePrimaryRead"></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editPlaceTypeDropDown">Place Type</label></th>\n' +
        '                    <td><select style="width: 100%" class="form-control select2"\n' +
        '                                id="editPlaceTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                <tr>\n' +
        '                    <th><label for="editPlacementTypeDropDown">Placement Type</label></th>\n' +
        '                    <td><select style="width: 100%" class="form-control select2"\n' +
        '                                id="editPlacementTypeDropDown">\n' +
        '                        <option></option>\n' +
        '                    </select></td>\n' +
        '                </tr>\n' +
        '                </tbody>\n' +
        '            </table>\n' +
        '        </form>\n' +
        '    </div>\n' +
        '    <div class="col-6">\n' +
        '        <form class="needs-validation" id="editRepsForm">\n' +
        '           <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '           <tbody id="editRepsTable">' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Representation Information</h5></td>\n' +
        '                </tr>' +
        '           </tbody></table>\n' +
    '        </form>\n' +
        '        <form class="needs-validation" id="editLayoutsForm">\n' +
        '           <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '           <tbody id="editLayoutsTable">' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Layout Information</h5></td>\n' +
        '                </tr>' +
        '           </tbody></table>\n' +
        '        </form>\n' +
        '        <form class="needs-validation" id="editFacesForm">\n' +
        '           <table class="table table-hover table-borderless table-bordered table-sm">\n' +
        '           <tbody id="editFacesTable">' +
        '                <tr>\n' +
        '                    <td colspan="2" class="text-center table-header"><h5>Face Information</h5></td>\n' +
        '                </tr>' +
        '           </tbody></table>\n' +
    '        </form>\n' +
        '        <form class="needs-validation" id="editSpotsForm">\n' +
        '           <table class="table table-hover table-borderless table-bordered table-sm">\n' +
    '           <tbody id="editSpotsTable">' +
    '                <tr>\n' +
    '                    <td colspan="2" class="text-center table-header"><h5>Spot Information</h5></td>\n' +
    '                </tr>' +
    '           </tbody></table>\n'+
    '        </form>\n' +
        '    </div>\n' +
        '</div>\n' +
        '<br>\n' +
        '<div class="row">\n' +
        '    <div class="col">\n' +
        '        <button id="editInventorySubmitBtn" type="button" class="btn-block btn-info">Save Record</button>\n' +
        '    </div>\n' +
        '</div>\n' +
        '<br>\n' +
        '<div class="row" id="editMessageDiv" hidden>\n' +
        '</div>');
    $.each(d['representations'], function (index,value){
        $("#editRepsTable").append(
            '    <tr>\n' +
            '        <th><label for="editParentAccountDropDown'+index+'">Parent Account</label><span class="requiredForm">*</span></th>\n' +
            '        <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
            '                    id="editParentAccountDropDown'+index+'">\n' +
            '            <option></option>\n' +
            '        </select>\n' +
            '        </td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editAccountDropDown'+index+'">Account</label><span class="requiredForm">*</span></th>\n' +
            '        <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
            '                    id="editAccountDropDown'+index+'"></select></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editRepTypeDropDown'+index+'">Representation Type</label><span class="requiredForm">*</span></th>\n' +
            '        <td><select required aria-required="true" style="width: 100%" class="form-control select2"\n' +
            '                    id="editRepTypeDropDown'+index+'"></select>\n' +
            '            <div class="invalid-feedback">\n' +
            '                Please use this and the previous fields to select an operator and representation type. If the operator you need is not here, please create a new operator record seperately.\n' +
            '            </div>\n' +
            '        </td>\n' +
            '    </tr>'+
            '    <tr>\n' +
            '        <th><hr></th>\n' +
            '        <td><hr></td>\n' +
            '    </tr>'
        )
    })
    $.each(d['layouts'],function (layoutindex,layoutvalue){
        let layoutid = layoutvalue['id']
        $("#editLayoutsTable").append(
            '    <tr>\n' +
            '        <th><label for="editLayoutID'+layoutid+'">Layout ID</label></th>\n' +
            '        <td><input disabled type="number" class="form-control" id="editLayoutID'+layoutid+'"></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editLayoutSOV'+layoutid+'">Layout Share of Voice</label></th>\n' +
            '        <td><input type="number" class="form-control" id="editLayoutSOV'+layoutid+'"></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editLayoutRotatingTF'+layoutid+'">Layout Rotating</label></th>\n' +
            '        <td><select\n' +
            '                class="form-control select2" id="editLayoutRotatingTF'+layoutid+'">\n' +
            '            <option>false</option>\n' +
            '            <option>true</option>\n' +
            '        </select></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editLayoutFullMotionTF'+layoutid+'">Layout Full Motion</label></th>\n' +
            '        <td><select\n' +
            '                class="form-control select2" id="editLayoutFullMotionTF'+layoutid+'">\n' +
            '            <option>false</option>\n' +
            '            <option>true</option>\n' +
            '        </select></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editLayoutPartialMotionTF'+layoutid+'">Layout Partial Motion</label></th>\n' +
            '        <td><select\n' +
            '                class="form-control select2" id="editLayoutPartialMotionTF'+layoutid+'">\n' +
            '            <option>false</option>\n' +
            '            <option>true</option>\n' +
            '        </select></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editLayoutInteractiveTF'+layoutid+'">Layout Interactive</label></th>\n' +
            '        <td><select\n' +
            '                class="form-control select2" id="editLayoutInteractiveTF'+layoutid+'">\n' +
            '            <option>false</option>\n' +
            '            <option>true</option>\n' +
            '        </select></td>\n' +
            '    </tr>\n' +
            '    <tr>\n' +
            '        <th><label for="editLayoutAudioTF'+layoutid+'">Layout Audio</label></th>\n' +
            '        <td><select\n' +
            '                class="form-control select2" id="editLayoutAudioTF'+layoutid+'">\n' +
            '            <option>false</option>\n' +
            '            <option>true</option>\n' +
            '        </select></td>\n' +
            '    </tr>' +
            '    <tr>\n' +
            '        <th><hr></th>\n' +
            '        <td><hr></td>\n' +
            '    </tr>'
        )
        $.each(d['layouts'][layoutindex]['faces'], function (faceindex, facevalue){
            let faceid = facevalue['id']
            $("#editFacesTable").append(
                '    <tr>\n' +
                '        <th><label for="editFaceLayoutID'+faceid+'">Faces Layout ID</label></th>\n' +
                '        <td><input disabled type="number" class="form-control" id="editFaceLayoutID'+faceid+'"></td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th><label for="editFaceID'+faceid+'">Face ID</label></th>\n' +
                '        <td><input disabled type="number" class="form-control" id="editFaceID'+faceid+'"></td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th>\n' +
                '            <div style="display: flex">\n' +
                '                <div><label for="editFaceHeightInches'+faceid+'">Face Height</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
                '                <div id="editheightFaceOffToggleDiv"><i title="Toggle Feet edit" id="editheightFaceOffToggle"\n' +
                '                                                        class="fas fa-toggle-off"></i></div>\n' +
                '                <div id="editheightFaceOnToggleDiv" hidden><i title="Toggle Feet edit" id="editheightFaceOnToggle"\n' +
                '                                                              class="fas fa-toggle-on"></i></div>\n' +
                '            </div>\n' +
                '        </th>\n' +
                '        <td>\n' +
                '            <div style="display: flex">\n' +
                '                <div id="editheightFaceFtDiv" hidden>\n' +
                '                    <input type="number" class="form-control" id="editFaceHeightFeet'+faceid+'"\n' +
                '                           placeholder="Height Feet">\n' +
                '                </div>\n' +
                '                &nbsp&nbsp\n' +
                '                <div id="editheightFaceInDiv'+faceid+'">\n' +
                '                    <input required aria-required="true" type="number" class="form-control"\n' +
                '                           id="editFaceHeightInches'+faceid+'"\n' +
                '                           placeholder="Height Inches">\n' +
                '                    <div class="invalid-feedback">\n' +
                '                        Please enter a height in inches. It should not exceed the height of the Frame.\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th>\n' +
                '            <div style="display: flex">\n' +
                '                <div><label for="editFaceWidthInches'+faceid+'">Face Width</label><span class="requiredForm">* &nbsp&nbsp</span></div>\n' +
                '                <div id="editwidthFaceOffToggleDiv"><i title="Toggle Feet edit" id="editwidthFaceOffToggle"\n' +
                '                                                       class="fas fa-toggle-off"></i></div>\n' +
                '                <div id="editwidthFaceOnToggleDiv" hidden><i title="Toggle Feet edit" id="editwidthFaceOnToggle"\n' +
                '                                                             class="fas fa-toggle-on"></i></div>\n' +
                '            </div>\n' +
                '        </th>\n' +
                '        <td>\n' +
                '            <div style="display: flex">\n' +
                '                <div id="editwidthFaceFtDiv" hidden>\n' +
                '                    <input type="number" class="form-control" id="editFaceWidthFeet"\n' +
                '                           placeholder="Width Feet">\n' +
                '                </div>\n' +
                '                &nbsp&nbsp\n' +
                '                <div id="editwidthFaceInDiv'+faceid+'">\n' +
                '                    <input required aria-required="true" type="number" class="form-control"\n' +
                '                           id="editFaceWidthInches'+faceid+'"\n' +
                '                           placeholder="Width Inches">\n' +
                '                    <div class="invalid-feedback">\n' +
                '                        Please enter a width in inches. It should not exceed the width of the Frame.\n' +
                '                    </div>\n' +
                '                </div>\n' +
                '            </div>\n' +
                '        </td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th><label for="editFacePixelHeight'+faceid+'">Face Pixel Height</label></th>\n' +
                '        <td><input type="number" class="form-control" id="editFacePixelHeight'+faceid+'"></td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th><label for="editFacePixelWidth'+faceid+'">Face Pixel Width</label></th>\n' +
                '        <td><input type="number" class="form-control" id="editFacePixelWidth'+faceid+'"></td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th><label for="editFaceTopPixelXHeight'+faceid+'">Face Top Pixel X Height</label></th>\n' +
                '        <td><input type="number" class="form-control" id="editFaceTopPixelXHeight'+faceid+'"></td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '        <th><label for="editFaceTopPixelYWidth'+faceid+'">Face Top Pixel Y Width</label></th>\n' +
                '        <td><input type="number" class="form-control" id="editFaceTopPixelYWidth'+faceid+'"></td>\n' +
                '    </tr>'+
                '    <tr>\n' +
                '        <th><hr></th>\n' +
                '        <td><hr></td>\n' +
                '    </tr>'
            )
            $.each(d['layouts'][layoutindex]['faces'][faceindex]['spots'], function (spotindex,spotvalue){
                let spotid = spotvalue['id']
                $("#editSpotsTable").append(
                    '    <tr>\n' +
                    '        <th><label for="editSpotLayoutID'+spotid+'">Spots Layout ID</label></th>\n' +
                    '        <td><input disabled type="text" class="form-control" id="editSpotLayoutID'+spotid+'"></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotFaceID'+spotid+'">Spots Face ID</label></th>\n' +
                    '        <td><input disabled type="text" class="form-control" id="editSpotFaceID'+spotid+'"></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotID'+spotid+'">Spot ID</label></th>\n' +
                    '        <td><input disabled type="text" class="form-control" id="editSpotID'+spotid+'"></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editOperatorSpotID'+spotid+'">Operator Spot ID</label><span class="requiredForm">*</span></th>\n' +
                    '        <td><input required aria-required="true" type="text" class="form-control" id="editOperatorSpotID'+spotid+'"\n' +
                    '                   placeholder="ID Used by the Operator">\n' +
                    '            <div class="invalid-feedback">\n' +
                    '                Please enter an operator spot id\n' +
                    '            </div>\n' +
                    '        </td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editPublisherUniqueID'+spotid+'">Publisher Unique ID</label></th>\n' +
                    '        <td><input type="text" class="form-control" id="editPublisherUniqueID'+spotid+'"></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotLen'+spotid+'">Spot Length</label></th>\n' +
                    '        <td><input type="number" class="form-control" id="editSpotLen'+spotid+'"></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotRotatingTF'+spotid+'">Spot Rotating</label></th>\n' +
                    '        <td><select\n' +
                    '                class="form-control select2" id="editSpotRotatingTF'+spotid+'">\n' +
                    '            <option>false</option>\n' +
                    '            <option>true</option>\n' +
                    '        </select></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotFullMotionTF'+spotid+'">Spot Full Motion</label></th>\n' +
                    '        <td><select\n' +
                    '                class="form-control select2" id="editSpotFullMotionTF'+spotid+'">\n' +
                    '            <option>false</option>\n' +
                    '            <option>true</option>\n' +
                    '        </select></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotPartialMotionTF'+spotid+'">Spot Partial Motion</label></th>\n' +
                    '        <td><select\n' +
                    '                class="form-control select2" id="editSpotPartialMotionTF'+spotid+'">\n' +
                    '            <option>false</option>\n' +
                    '            <option>true</option>\n' +
                    '        </select></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotInteractiveTF'+spotid+'">Spot Interactive</label></th>\n' +
                    '        <td><select\n' +
                    '                class="form-control select2" id="editSpotInteractiveTF'+spotid+'">\n' +
                    '            <option>false</option>\n' +
                    '            <option>true</option>\n' +
                    '        </select></td>\n' +
                    '    </tr>\n' +
                    '    <tr>\n' +
                    '        <th><label for="editSpotAudioTF'+spotid+'">Spot Audio</label></th>\n' +
                    '        <td><select\n' +
                    '                class="form-control select2" id="editSpotAudioTF'+spotid+'">\n' +
                    '            <option>false</option>\n' +
                    '            <option>true</option>\n' +
                    '        </select></td>\n' +
                    '    </tr>'+
                    '    <tr>\n' +
                    '        <th><hr></th>\n' +
                    '        <td><hr></td>\n' +
                    '    </tr>')
            })
        })
    })
    $("#editclassTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Classifciation Type",
        data: classificationSelectVals
    })
    $("#editmediaTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Media Type",
        data: mediaSelectVals
    })
    $("#editillTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Illumination Type",
        data: illuminationSelectVals
    })
    $("#editconstructionTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Construction Type",
        data: constructionSelectVals
    })
    $("#editPlaceTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Place Type",
        data: placeTypeSelectVals
    })
    $("#editPlacementTypeDropDown").select2({
        theme: 'bootstrap4',
        placeholder: "Select Placement Type",
        data: placementSelectVals
    })


    $('#editheightFrameOffToggle').on('click', function (){
        $('#editheightFrameOffToggleDiv').attr('hidden', true)
        $("#editheightFrameOnToggleDiv").attr('hidden', false)
        $("#editheightFrameFtDiv").attr('hidden',false)
    })
    $('#editheightFrameOnToggle').on('click', function (){
        $("#editheightFrameOnToggleDiv").attr('hidden', true)
        $('#editheightFrameOffToggleDiv').attr('hidden', false)
        $("#editheightFrameFtDiv").attr('hidden',true)
    })
    $('#editwidthFrameOffToggle').on('click', function (){
        $('#editwidthFrameOffToggleDiv').attr('hidden', true)
        $("#editwidthFrameOnToggleDiv").attr('hidden', false)
        $("#editwidthFrameFtDiv").attr('hidden',false)
    })
    $('#editwidthFrameOnToggle').on('click', function (){
        $("#editwidthFrameOnToggleDiv").attr('hidden', true)
        $('#editwidthFrameOffToggleDiv').attr('hidden', false)
        $("#editwidthFrameFtDiv").attr('hidden',true)
    })
    $('#editheightFaceOffToggle').on('click', function (){
        $('#editheightFaceOffToggleDiv').attr('hidden', true)
        $("#editheightFaceOnToggleDiv").attr('hidden', false)
        $("#editheightFaceFtDiv").attr('hidden',false)
    })
    $('#editheightFaceOnToggle').on('click', function (){
        $("#editheightFaceOnToggleDiv").attr('hidden', true)
        $('#editheightFaceOffToggleDiv').attr('hidden', false)
        $("#editheightFaceFtDiv").attr('hidden',true)
    })
    $('#editwidthFaceOffToggle').on('click', function (){
        $('#editwidthFaceOffToggleDiv').attr('hidden', true)
        $("#editwidthFaceOnToggleDiv").attr('hidden', false)
        $("#editwidthFaceFtDiv").attr('hidden',false)
    })
    $('#editwidthFaceOnToggle').on('click', function (){
        $("#editwidthFaceOnToggleDiv").attr('hidden', true)
        $('#editwidthFaceOffToggleDiv').attr('hidden', false)
        $("#editwidthFaceFtDiv").attr('hidden',true)
    })
    $('#orientationDirOffToggle').on('click', function (){
        $("#editFrameOrientation").attr('required',false)
        $("#editorientationDirDropDown").attr('required',true)
        $('#editorientationDirOffToggleDiv').attr('hidden', true)
        $("#editorientationDirOnToggleDiv").attr('hidden', false)
        $("#editorientationDirDiv").attr('hidden',false)
        $("#editorientationDegDiv").attr('hidden',true)
    })
    $('#orientationDirOnToggle').on('click', function (){
        $("#editFrameOrientation").attr('required',true)
        $("#editorientationDirDropDown").attr('required',false)
        $("#editorientationDirOnToggleDiv").attr('hidden', true)
        $('#editorientationDirOffToggleDiv').attr('hidden', false)
        $("#editorientationDirDiv").attr('hidden',true)
        $("#editorientationDegDiv").attr('hidden',false)
    })


    $("#editInventorySubmitBtn").on("click",function (){
        editInventoryFromForm()
    })
    // console.log(d)
    // console.log(d['digital'].toString())
    //Frame Form
    $("#editOperatorFrameID").val(d['plant_frame_id'])
    $("#editConstructionName").val(d['construction']['name'])
    $("#editclassTypeDropDown").val(d['classification_type']['id']).change()
    $("#editFrameHeightInches").val(d['max_height'])
    $("#editFrameWidthInches").val(d['max_width'])
    $("#editFramePixelHeight").val(d['max_pixel_height'])
    $("#editFramePixelWidth").val(d['max_pixel_width'])
    $("#editFrameIllStart").val(d['illumination_start_time'])
    $("#editFrameIllEnd").val(d['illumination_end_time'])
    $("#editFrameMediaName").val(d['media_name'])
    $("#editconstructionTypeDropDown").val(d['construction']['construction_type']['id']).change()
    $("#editmediaTypeDropDown").val(d['media_type']['id']).change()
    $("#editFrameDigitalTF").val(d['digital'].toString()).change()
    $("#editFrameRotatingTF").val(d['rotating'].toString()).change()
    $("#editFrameFullMotionTF").val(d['full_motion'].toString()).change()
    $("#editFramePartialMotionTF").val(d['partial_motion'].toString()).change()
    $("#editFrameInteractiveTF").val(d['interactive'].toString()).change()
    $("#editFrameAudioTF").val(d['audio'].toString()).change()
    $("#editFrameDescription").val(d['description'])

    //Location Form
    $("#editFrameLatitude").val(d['location']['latitude'])
    $("#editFrameLongitude").val(d['location']['longitude'])
    $("#editFrameOrientation").val(d['location']['orientation'])
    $("#editFramePrimaryArtery").val(d['location']['primary_artery'])
    $("#editFramePrimaryRead").val(d['location']['primary_read'])
    // $("#editPlaceTypeDropDown").val(d['location']['place_type']['id']).change()
    // $("#editPlacementTypeDropDown").val(d['placement_type']['id']).change()

    //Representation Form
    $.each(d['representations'],function (index,value){
        // console.log(representationTypeSelectVals)
        $("#editParentAccountDropDown"+index).select2({
            theme: 'bootstrap4',
            placeholder: "Select Parent Account",
            data: parent_accounts
        })
        $("#editAccountDropDown"+index).select2({
            theme: 'bootstrap4',
            data:accounts
        })
        $("#editRepTypeDropDown"+index).select2({
            theme: 'bootstrap4',
            data: representationTypeSelectVals

        })
        $("#editParentAccountDropDown"+index).val(d['representations'][index]['account']['parent_account_id']).change()
        $("#editAccountDropDown"+index).trigger("change")
        $("#editAccountDropDown"+index).val(d['representations'][index]['account']['id']).change()
        $("#editRepTypeDropDown"+index).trigger("change")
        $("#editRepTypeDropDown"+index).val(d['representations'][index]['representation_type']['id']).change()
    })

    //Layouts/Faces/Spots Forms
    $.each(d['layouts'],function (layoutindex,layoutvalue){
        let layoutid = layoutvalue['id']
        $("#editLayoutID"+layoutid).val(layoutvalue['id'])
        $("#editLayoutSOV"+layoutid).val(layoutvalue['share_of_voice'])
        $("#editLayoutRotatingTF"+layoutid).val(layoutvalue['rotating'].toString()).change()
        $("#editLayoutFullMotionTF"+layoutid).val(layoutvalue['full_motion'].toString()).change()
        $("#editLayoutPartialMotionTF"+layoutid).val(layoutvalue['partial_motion'].toString()).change()
        $("#editLayoutInteractiveTF"+layoutid).val(layoutvalue['interactive'].toString()).change()
        $("#editLayoutAudioTF"+layoutid).val(layoutvalue['audio'].toString()).change()
        $.each(d['layouts'][layoutindex]['faces'],function (faceindex,facevalue){
            let faceid = facevalue['id']
            $("#editFaceLayoutID"+faceid).val(layoutvalue['id'])
            $("#editFaceID"+faceid).val(facevalue['id'])
            $("#editFaceHeightInches"+faceid).val(facevalue['height'])
            $("#editFaceWidthInches"+faceid).val(facevalue['width'])
            $("#editFacePixelHeight"+faceid).val(facevalue['pixel_height'])
            $("#editFacePixelWidth"+faceid).val(facevalue['pixel_width'])
            $("#editFaceTopPixelXHeight"+faceid).val(facevalue['top_left_pixel_x'])
            $("#editFaceTopPixelWidthYWidth"+faceid).val(facevalue['top_left_pixel_y'])
            $.each(d['layouts'][layoutindex]['faces'][faceindex]['spots'],function (spotindex,spotvalue){
                let spotid = spotvalue['id']
                $("#editSpotLayoutID"+spotid).val(layoutvalue['id'])
                $("#editSpotFaceID"+spotid).val(facevalue['id'])
                $("#editSpotID"+spotid).val(spotvalue['id'])
                $("#editOperatorSpotID"+spotid).val(spotvalue['plant_spot_id'])
                $("#editSpotLen"+spotid).val(spotvalue['length'])
                $("#editSpotRotatingTF"+spotid).val(spotvalue['rotating'].toString()).change()
                $("#editSpotFullMotionTF"+spotid).val(spotvalue['full_motion'].toString()).change()
                $("#editSpotPartialMotionTF"+spotid).val(spotvalue['partial_motion'].toString()).change()
                $("#editSpotInteractiveTF"+spotid).val(spotvalue['interactive'].toString()).change()
                $("#editSpotAudioTF"+spotid).val(spotvalue['audio'].toString()).change()
            })
        })
    })
}
