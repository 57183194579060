import {otherinfo} from "../supportFunctions/consts";
import {formatNumber, round} from "../supportFunctions/mathFunctions";


export function indLineInfo(props,linegeojsonObject,linewktGeom,oppseg,oppseglanes,oppseggeom) {
    // console.log(props)
    let segid
    let stname
    let network
    if (props.segment_reg){
        segid = props.segment_reg
    }
    else {
        segid = props.segment
    }
    if (props.st_name === ''){
        stname = 'No Street Name'
    }
    else{
        stname = props.st_name
    }
    if (props.layer === "sl_national_network.sl_2016r3_new_adjusted"){
        network = '2016 Network'
    }
    else {
        network = '2021 Network'
    }

    otherinfo.append(
        '<table class="table table-striped table-bordered table-sm">'+
        '<tbody>' +
        '   <tr class="table-info">' +
        '      <th scope="row">HERE Segment ID</th>' +
        '      <td>'+ segid + ' '+ '<button class="clipboard" id="linkcopy" data-clipboard-text="' + segid + '"><i class="far fa-clipboard"></i></button></td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Street Name & Direction</th>' +
        '      <td>'+ stname + ", " + props.direction  +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Functional Class</th>' +
        '      <td>'+ props.func_class +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Segment Heading</th>' +
        '      <td>'+ formatNumber(round(props.heading, 0)) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Opposite Segment</th>' +
        '      <td>'+ oppseg +'</td>' +
        '   </tr></button>' +
        '   <tr>'+
        '      <th scope="row">Veh Total Circulation</th>' +
        '      <td>'+ formatNumber(round(props.veh_aadt, 0)) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Veh Total AADT</th>' +
        '      <td>'+ formatNumber(round(props.veh_aadt/props.wav_occ, 0)) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Ped Total AADT</th>' +
        '       <td>'+ formatNumber(round(props.ped_aadt, 0)) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Segment Avg Speed </th>' +
        '       <td>'+ round(props.wav_speed, 2) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Segment Avg Occupancy </th>' +
        '       <td>'+ round(props.wav_occ, 2) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Segment Avg Confidence </th>' +
        '       <td>'+ round(props.wav_con, 2) +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Network Type </th>' +
        '       <td>'+ network +'</td>' +
        '   </tr>' +
            //opposite segment & Current seg geometries hidden from display
        '   <tr>' +
        '       <th scope="row" hidden>Opposite Segment Lanes</th>'+
        '       <td id="oppSegLanes" hidden>'+oppseglanes+'</td>'+
        '   </tr>' +
        '   <tr>' +
        '       <th scope="row" hidden>Seg WKT</th>'+
        '       <td hidden>'+linewktGeom+'</td>'+
        '   </tr>' +
        '   <tr>' +
        '       <th scope="row" hidden>SegGeoJSON</th>'+
        '       <td hidden>'+linegeojsonObject+'</td>'+
        '   </tr>' +
        '   <tr>' +
        '       <th scope="row" hidden>Opposite Seg WKT</th>'+
        '       <td hidden>'+oppseggeom+'</td>'+
        '   </tr>' +
        '   <tr>' +
        '       <th class="hiddentablerow" scope="row" id="addToAssignmentRow" hidden></th>'+
        '       <td class="hiddentablerow" hidden id="addToAssignmentButton"><button name="segbutton" id="' + segid + '" type="button" class="btn btn-secondary btn-sm" data-linewkt="' + linewktGeom + '" data-oppseg="' + oppseg + '" data-oppsegwkt="' + oppseggeom + '" data-segLanes="' + props.lanes + '" data-oppsegLanes="' + oppseglanes + '" >Add to Assignment</button></td>'+
        '   </tr>' +
        '</tbody>'
    )
}

export function cstationLineInfo(props) {
    let pedcount = 0
    if(props.ped_cnt){
        pedcount = props.ped_cnt
    }
    otherinfo.append(
        '<table class="table table-striped table-bordered table-sm">'+
        '<tbody>' +
        '   <tr class="table-info">' +
        '      <th scope="row">CStation ID</th>' +
        '      <td>'+ props.cstationid+ ' '+ '<button class="clipboard" id="linkcopy" data-clipboard-text="' + props.cstationid + '"><i class="far fa-clipboard"></i></button></td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Street Name & Direction</th>' +
        '      <td>'+ props.street_name + ", " + props.direction  +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Functional Class</th>' +
        '      <td>'+ props.fcc +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '      <th scope="row">Veh Total AADT</th>' +
        '      <td>'+ props.veh_cnt +'</td>' +
        '   </tr>' +
        '   <tr>'+
        '       <th scope="row">Ped Total AADT</th>' +
        '       <td>'+ pedcount +'</td>' +
        '   </tr>' +
        '</tbody>'
    )
}
