import {Tile} from "ol/layer";
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import LayerGroup from "ol/layer/Group";

export const esri_st_base = new Tile({
    title: 'Streets Basemap',
    type: 'base',
    visible: true,
    source: new XYZ({
        attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
            'rest/services/World_Street_Map/MapServer">ArcGIS</a>',
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
            'World_Street_Map/MapServer/tile/{z}/{y}/{x}'
    })
});
export const bing_st_base = new TileLayer({
    title: 'Streets(Bing)',
    type: 'base',
    visible: false,
    source: new BingMaps({
        key: process.env.bing_api_key,
        imagerySet: 'Road',
        maxZoom: 19
    })

});
export const bing_img_base = new TileLayer({
    title: 'Aerial(Bing)',
    type: 'base',
    visible: false,
    source: new BingMaps({
        key:process.env.bing_api_key,
        imagerySet: 'AerialWithLabels',
        maxZoom: 19
    })

});
export const esri_img_base = new LayerGroup({
    title: 'Aerial(ESRI)',
    type: 'base',
    combine: true,
    visible: false,
    layers: [
        new Tile({
            source: new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                    'rest/services/World_Imagery/MapServer">ArcGIS</a>',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                    'World_Imagery/MapServer/tile/{z}/{y}/{x}'
            })
        }),
        new Tile({
            source: new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                    'rest/services/Reference/World_Transportation/MapServer">ArcGIS</a>',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                    'Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}'
            })
        }),
        new Tile({
            source: new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                    'rest/services/Reference/World_Boundaries_and_Places/MapServer">ArcGIS</a>',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                    'Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}'
            })
        }),

    ]
});