import {
    clearInfoDiv,
    clearMapUtilsBody,
    clearMapUtilsHeader,
    clearOtherInfoDiv,
    clearSubMapRow,
    hideAssignManagementButtons,
    hideAssignmentTable,
    searchfield
} from "../supportFunctions/consts";
import {
    clearAssignmentLayer,
    clearCoordinateSearchLayer,
    coordinateSearchLayer,
    coordinateSearchSource,
} from "../layers/activeLayers";
import {coordinateDisplay} from "../map_events/coordinateFunctions";
import {coordinateSearchIcon} from "../map_styles/pointStyles";
import {GeoJSON} from "ol/format";
import {setNewMapCenter, transform4326to3857} from "../supportFunctions/mapFunctions";
import {streetDefaultStyle} from "../map_styles/lineStyle";


export function coordinateSearch(){
    clearInfoDiv()
    clearOtherInfoDiv()
    clearAssignmentLayer()
    streetDefaultStyle()
    clearSubMapRow()
    hideAssignManagementButtons()
    hideAssignmentTable()
    clearMapUtilsHeader()
    clearMapUtilsBody()
    clearCoordinateSearchLayer()
    //get search field value and split the string at the comma
    const sll = searchfield.val().split(',');
    //transform coordinates to 3857 so they can be mapped
    let newcoords =transform4326to3857(parseFloat(sll[0]),parseFloat(sll[1]))
    //re-center
    setNewMapCenter(newcoords);
    //display coordinate
    coordinateDisplay([parseFloat(sll[0]),parseFloat(sll[1])])
    //empty search field
    searchfield.val('');
    //create geojson and read it into the coordinate search layer source to display
    const selectedGeoJSON ="{\"type\": \"FeatureCollection\",\"crs\": {\"type\": \"name\", \"properties\": {\"name\": \"EPSG:3857\"}}, \"features\": [{\"type\": \"Feature\", \"geometry\": {\"type\": \"Point\", \"coordinates\": ["+newcoords+"]}}]}";
    const selectedGeoJSONFeatures = new GeoJSON().readFeatures(selectedGeoJSON);
    coordinateSearchSource.addFeatures(selectedGeoJSONFeatures);
    coordinateSearchLayer.setStyle(coordinateSearchIcon)
}