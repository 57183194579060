import $ from "jquery";
import {
    classificationSelectVals,
    constructionSelectVals,
    illuminationSelectVals,
    mediaSelectVals,
    placementSelectVals,
    statusSelectVals
} from "../supportFunctions/apiTypes";
import {convertIntoFtArray} from "../supportFunctions/mathFunctions";

export function createFrameEditor(features) {
    $("#editBody").attr('hidden',false)
    let enabledOrdisabled
    if ([2,3,4,5,6].includes(features["classification_type"]["id"]))
    {
        enabledOrdisabled = "enabled"
    }
    else {
        enabledOrdisabled = "enabled"
    }

    $("#mapUtilsHeader").html('<h5>EDITING Frame Info</h5>')
    // $("#editorLayouts").html('<button id="editRepsBtn" type="button" class="btn btn-warning btn-sm" disabled>Edit Representation</button>')
    try {
        $("#inventoryFrameRow").html(

            '<table class="table table-striped table-bordered table-sm">' +
            '<tbody>' +
            '   <tr>' +
            '       <th scope="row">Longitude</th>' +
            '       <td>' + '<input id="currentLon" value="' + features["location"]['longitude'] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Latitude</th>' +
            '       <td>' + '<input id="currentLat" value="' + features["location"]['latitude'] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Orientation</th>' +
            '       <td>' + '<input id="currentOrientation" value="' + features["location"]['orientation'] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Operator ID</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentOpId" value="' + features["plant_frame_id"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Construction Name</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentConsName" value="' + features["construction"]["name"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Construction Date</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentConsDate" value="' + features["construction_date"] + '">' + '</td>' +
            '   </tr>' +
            '                <tr>\n' +
            '                    <th>\n' +
            '                        <div style="display: flex">\n' +
            '                            <div><label for="currentFrameHeightInches">Frame Height</label></div>\n' +
            '                            <div id="currentheightFrameOffToggleDiv"><i title="Toggle Feet Input" id="currentheightFrameOffToggle"\n' +
            '                                                                class="fas fa-toggle-off"></i></div>\n' +
            '                            <div id="currentheightFrameOnToggleDiv" hidden><i title="Toggle Feet Input" id="currentheightFrameOnToggle"\n' +
            '                                                                      class="fas fa-toggle-on"></i></div>\n' +
            '                        </div>\n' +
            '                    </th>\n' +
            '                    <td>\n' +
            '                        <div style="display: flex">\n' +
            '                            <div id="currentheightFrameFtDiv">\n' +
            '                                <input type="number" class="form-control" id="currentFrameHeightFeet"\n' +
            '                                       placeholder="Height Feet" value="' + convertIntoFtArray(features["max_height"])[0] + '">\n' +
            '                            </div>\n' +
            '                            &nbsp&nbsp\n' +
            '                            <div id="currentFrameheightFrameInDiv">\n' +
            '                                <input required aria-required="true" type="number" class="form-control"\n' +
            '                                       id="currentFrameHeightInches"\n' +
            '                                       placeholder="Height Inches" value="' + convertIntoFtArray(features["max_height"])[1] + '">\n' +
            '                            </div>\n' +
            '                        </div>\n' +
            '                    </td>\n' +
            '                </tr>\n' +

            '   </tr>' +
            '                <tr>\n' +
            '                    <th>\n' +
            '                        <div style="display: flex">\n' +
            '                            <div><label for="currentFrameWidthInches">Frame Width</label></div>\n' +
            '                            <div id="currentwidthFrameOffToggleDiv"><i title="Toggle Feet Input" id="currentwidthFrameOffToggle"\n' +
            '                                                                class="fas fa-toggle-off"></i></div>\n' +
            '                            <div id="currentwidthFrameOnToggleDiv" hidden><i title="Toggle Feet Input" id="currentwidthFrameOnToggle"\n' +
            '                                                                      class="fas fa-toggle-on"></i></div>\n' +
            '                        </div>\n' +
            '                    </th>\n' +
            '                    <td>\n' +
            '                        <div style="display: flex">\n' +
            '                            <div id="currentwidthFrameFtDiv">\n' +
            '                                <input type="number" class="form-control" id="currentFrameWidthFeet"\n' +
            '                                       placeholder="Width Feet" value="' + convertIntoFtArray(features["max_width"])[0] + '">\n' +
            '                            </div>\n' +
            '                            &nbsp&nbsp\n' +
            '                            <div id="currentFramewidthFrameInDiv">\n' +
            '                                <input required aria-required="true" type="number" class="form-control"\n' +
            '                                       id="currentFrameWidthInches"\n' +
            '                                       placeholder="Width Inches" value="' + convertIntoFtArray(features["max_width"])[1] + '">\n' +
            '                            </div>\n' +
            '                        </div>\n' +
            '                    </td>\n' +
            '                </tr>\n' +
            '   <tr>' +
            '       <th scope="row">Frame Pixel Height</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentFrPxHt" value="' + features["max_pixel_height"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Pixel Width</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentFrPxWd" value="' + features["max_pixel_width"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Class</th>' +
            '       <td>' + '<select class="form-control select2" id="currentFrClass" style="width: 100%"><option></option></select>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Construction Type</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentConsTypName" style="width: 100%"><option></option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Media Type</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentMedTypName" style="width: 100%"><option></option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Media Name</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentFrMedName" value="' + features["media_name"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Status</th>' +
            '       <td>' + '<select class="form-control select2" id="currentFrStat" style="width: 100%"><option></option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Placement Type</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentPlacementTypeName" style="width: 100%"><option></option></select>' + '</td>' +
            '   </tr>' +
            // '   <tr>' +
            // '       <th scope="row">Place Type</th>' +
            // '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentPlaceTypeName" style="width: 100%"><option></option></select>' + '</td>' +
            // '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Illumination Start</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentFrIllSt" value="' + features["illumination_start_time"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Illumination End</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentFrIllEnd" value="' + features["illumination_end_time"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Illumination Type</th>' +
            '       <td>' + '<select class="form-control select2" id="currentFrIllType" style="width: 100%"><option></option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Digital</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentFrDg" style="width: 100%"><option>false</option><option>true</option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Full Motion</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentFrfull_motion" style="width: 100%"><option>false</option><option>true</option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Partial Motion</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentFrpartial_motion" style="width: 100%"><option>false</option><option>true</option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Rotating</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentFrrotating" style="width: 100%"><option>false</option><option>true</option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Interactive</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentFrinteractive" style="width: 100%"><option>false</option><option>true</option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Audio</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="form-control select2" id="currentFraudio" style="width: 100%"><option>false</option><option>true</option></select>' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Engagement</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' id="currentFrEngage" value="' + features["engagement"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Primary Artery</th>' +
            '       <td>' + '<input  id="currentPrimArt" value="' + features['location']["primary_artery"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Primary Read</th>' +
            '       <td>' + '<input  id="currentRead" value="' + features['location']["primary_read"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Frame Description</th>' +
            '       <td>' + '<input  id="currentUpdateFrDesc" value="' + features["description"] + '">' + '</td>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Update Notes</th>' +
            '       <td>' + '<input  id="currentUpdateDesc">' + '</td>' +
            '   </tr>' +

            '</tbody>' +
            '</table>'
        );
        $("#currentFrDg").val(features['digital'].toString()).change()
        $("#currentFrfull_motion").val(features['full_motion'].toString()).change()
        $("#currentFrpartial_motion").val(features['partial_motion'].toString()).change()
        $("#currentFrrotating").val(features['rotating'].toString()).change()
        $("#currentFrinteractive").val(features['interactive'].toString()).change()
        $("#currentFraudio").val(features['audio'].toString()).change()
        $("#currentMedTypName").select2({
            data: mediaSelectVals
        })
        $("#currentFrIllType").select2({
            data: illuminationSelectVals
        })
        $("#currentFrIllType").val(features['illumination_type']['id'])
        $("#currentFrIllType").trigger('change')
        $("#currentMedTypName").val(features['media_type']['id'])
        $("#currentMedTypName").trigger('change')
        $("#currentPlacementTypeName").select2({
            data: placementSelectVals
        })
        // console.log(features)
        if(features['placement_type']){
            $("#currentPlacementTypeName").val(features['placement_type']['id'])
            $("#currentPlacementTypeName").trigger('change')
        }


        // $("#currentPlaceTypeName").select2({
        //     data: placeTypeSelectVals
        // })
        // $("#currentPlaceTypeName").val(features['place_type']['id'])
        // $("#currentPlaceTypeName").trigger('change')

        $("#currentConsTypName").select2({
            data: constructionSelectVals
        })
        $("#currentConsTypName").val(features['construction']['construction_type']['id'])
        $("#currentConsTypName").trigger('change')

        $("#currentFrStat").select2({
            data: statusSelectVals
        })
        $("#currentFrStat").val(features['status_type']['id'])
        $("#currentFrStat").trigger('change')

        $("#currentFrClass").select2({
            data: classificationSelectVals
        })
        $("#currentFrClass").val(features['classification_type']['id'])
        $("#currentFrClass").trigger('change')
        createLayoutEditor(features)
        // $("#editRepsBtn").on('click', async function () {
        //     createRepEditor()
        // })
        $('#currentheightFrameOffToggle').on('click', function (){
            $('#currentheightFrameOffToggleDiv').attr('hidden', true)
            $("#currentheightFrameOnToggleDiv").attr('hidden', false)
            $("#currentheightFrameFtDiv").attr('hidden',true)
            $("#currentFrameHeightInches").val(features['max_height'])
            $("#currentFrameHeightFeet").val(null)
        })
        $('#currentheightFrameOnToggle').on('click', function (){
            $("#currentheightFrameOnToggleDiv").attr('hidden', true)
            $('#currentheightFrameOffToggleDiv').attr('hidden', false)
            $("#currentheightFrameFtDiv").attr('hidden',false)
            $("#currentFrameHeightInches").val(convertIntoFtArray(features['max_height'])[1])
            $("#currentFrameHeightFeet").val(convertIntoFtArray(features['max_height'])[0])
        })
        $('#currentwidthFrameOffToggle').on('click', function (){
            $('#currentwidthFrameOffToggleDiv').attr('hidden', true)
            $("#currentwidthFrameOnToggleDiv").attr('hidden', false)
            $("#currentwidthFrameFtDiv").attr('hidden',true)
            $("#currentFrameWidthInches").val(features['max_width'])
            $("#currentFrameWidthFeet").val(null)
        })
        $('#currentwidthFrameOnToggle').on('click', function (){
            $("#currentwidthFrameOnToggleDiv").attr('hidden', true)
            $('#currentwidthFrameOffToggleDiv').attr('hidden', false)
            $("#currentwidthFrameFtDiv").attr('hidden',false)
            $("#currentFrameWidthInches").val(convertIntoFtArray(features['max_width'])[1])
            $("#currentFrameWidthFeet").val(convertIntoFtArray(features['max_width'])[0])
        })
    }
    catch (error){
        // console.log(error)
        $("#inventoryFrameRow").html(
            (
                '<h3>There was an error</h3>' +
                '<p>This occured when reading the data returned from the API</p>' +
                '<p>It is likely that some data is missing from the record in the database</p>' +
                '<p>Please see below for more information</p>' +
                '<hr>'+
                error +
                '<hr>'

            )
        )
    }

}

export async function createLayoutEditor(features) {
    let enabledOrdisabled
    if(features["classification_type"]["id"] === 4){
        enabledOrdisabled = "enabled"
    }
    else {
        enabledOrdisabled = "enabled"
    }
    // $("#layoutEditorHolderDiv").append(
    //     '        <div id="accordionInventoryDetailRow" class="card">' +
    //     '                <div class="card-header" id="headingInvDetail">' +
    //     '                    <h5 class="mb-0">' +
    //     '                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseInvDetail"' +
    //     '                                aria-expanded="true" aria-controls="collapseInvDetail">' +
    //     '                            Edit Frame Content' +
    //     '                        </button>' +
    //     '                    </h5>' +
    //     '                </div>' +
    //     '                <div id="collapseInvDetail" class="collapse" aria-labelledby="headingInvDetail"' +
    //     '                     data-parent="#accordionInventoryDetailRow">' +
    //     '                    <div class="card-body" style="padding-left: .25rem">' +
    //     '                          <div class="row" id="inventoryRecordRow">' +
    //     '                           </div>' +
    //     '                      </div>' +
    //     '                </div>' +
    //     '            </div>'
    // )
    $.each(features['layouts'], function (lindex, value) {
        // console.log(value)
        // console.log()
        // let audiostring = value['audio'].toString()
        // console.log(typeof audiostring)
        $("#inventoryRecordRow").append(
            '<div class="card" id="accordionInventoryLayoutRow' + value['id'] + '" style=" display:inline-flex">' +
            '                                    <div class="card-header" id="headingLayout' + value['id'] + '">' +
            '                                        <h5 class="mb-0">' +
            '                                            <button class="btn btn-link" data-toggle="collapse"' +
            '                                                    data-target="#collapseLayout' + value['id'] + '"' +
            '                                                    aria-expanded="true" aria-controls="collapseLayout' + value['id'] + '">' +
            '                                                Layout Details' +
            '                                            </button>' +
            // '                        <button disabled class="btn btn-warning btn-sm" id="addLayoutBtn">' +
            // '                            Add Layout' +
            // '                        </button>' +
            '                                        </h5>' +
            '                                    </div>' +
            '                                    <div id="collapseLayout' + value['id'] + '" class="collapse show"' +
            '                                         aria-labelledby="headingLayout' + value['id'] + '"' +
            '                                         data-parent="#accordionInventoryLayoutRow' + value['id'] + '">' +
            '                                        <div class="card-body">' +
            '                                            <div class="row">' +
            '                                                <div class="col" id="layoutDetail_' + value['id'] + '" style="display: grid">' +
            '<table class="table table-striped table-bordered table-sm">' +
            '<tbody>' +
            '   <tr>' +
            '       <th scope="row">Layout ID</th>' +
            '       <td>' + '<input disabled class="layouteditordetail" id="layout%id%' + value['id'] + '" value="' + value['id'] + '">' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Layout SOV</th>' +
            '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="layout%share_of_voice%' + value['id'] + '" value="' + value['share_of_voice'] + '">' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Layout Full Motion</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%full_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Layout Partial Motion</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%partial_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Layout Rotating</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%rotating%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Layout Interactive</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%interactive%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            '   </tr>' +
            '   <tr>' +
            '       <th scope="row">Layout Audio</th>' +
            '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%audio%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            '   </tr>' +
            '</tbody>' +
            '</table>'+
            // 'Layout ID: ' + '<input disabled class="layouteditordetail" id="layout%id%' + value['id'] + '" value="' + value['id'] + '">' +
            // 'Layout SOV: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="layout%share_of_voice%' + value['id'] + '" value="' + value['share_of_voice'] + '">' +
            // 'Layout Full Motion: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%full_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            // 'Layout Partial Motion: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%partial_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            // 'Layout Rotating: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%rotating%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            // 'Layout Interactive: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%interactive%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            // 'Layout Audio: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="layout%audio%' + value['id'] + '"><option>false</option><option>true</option></select>' +
            '                                               </div>' +
            '                                            </div>' +
            '                                        </div>' +
            '<div id="accordionInventoryFaceRowforLayout' + value['id'] + '" style="display:flex"></div>' +
            '                                    </div>' +
            '                                </div>'
        )
        $(document.getElementById("layout%full_motion%" + value['id'])).val(value['full_motion'].toString()).change()
        $(document.getElementById("layout%partial_motion%" + value['id'])).val(value['partial_motion'].toString()).change()
        $(document.getElementById("layout%rotating%" + value['id'])).val(value['rotating'].toString()).change()
        $(document.getElementById("layout%interactive%" + value['id'])).val(value['interactive'].toString()).change()
        $(document.getElementById("layout%audio%" + value['id'])).val(value['audio'].toString()).change()
        $.each(features['layouts'][lindex]['faces'], function (findex, value) {
            $("#accordionInventoryFaceRowforLayout" + features['layouts'][lindex]['id']).append(
                '<div class="card" id="accordionInventoryFaceRow' + value['id'] + '" style="display: inline-flex">' +
                '                                    <div class="card-header" id="headingFace' + value['id'] + '">' +
                '                                        <h5 class="mb-0">' +
                '                                            <button class="btn btn-link" data-toggle="collapse"' +
                '                                                    data-target="#collapseFace' + value['id'] + '"' +
                '                                                    aria-expanded="true" aria-controls="collapseFace' + value['id'] + '">' +
                '                                                Face Details' +
                '                                            </button>' +
                // '                        <button disabled class="btn btn-warning btn-sm" id="addFaceBtn">' +
                // '                            Add Face' +
                // '                        </button>' +
                '                                        </h5>' +
                '                                    </div>' +
                '                                    <div id="collapseFace' + value['id'] + '" class="collapse show"' +
                '                                         aria-labelledby="headingFace' + value['id'] + '"' +
                '                                         data-parent="#accordionInventoryFaceRow' + value['id'] + '">' +
                '                                        <div class="card-body">' +
                '                                            <div class="row">' +
                '                                                <div class="col" id="FaceDetail_' + value['id'] + '" style="display: grid">' +
                '<table class="table table-striped table-bordered table-sm">' +
                '<tbody>' +
                '   <tr>' +
                '       <th scope="row">Face ID</th>' +
                '       <td>' + '<input disabled class="layouteditordetail" id="face%id%' + value['id'] + '" value="' + value['id'] + '">' +
                '   </tr>' +
                '   <tr>' +
                '       <th scope="row">Publisher Unique Face ID</th>' +
                '       <td>' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%publisher_unique_face_id%' + value['id'] + '" value="' + value['publisher_unique_face_id'] + '">' +
                '   </tr>' +
                '                <tr>\n' +
                '                    <th>\n' +
                '                        <div style="display: flex">\n' +
                '                            <div><label for="face%heightIn%' + value['id'] + '">Face Height</label></div>\n' +
                '                            <div id="face%heightOffToggleDiv%' + value['id'] + '"><i title="Toggle Feet Input" id="face%heightOffToggle%' + value['id'] + '"' +
                '                                                                class="fas fa-toggle-off"></i></div>\n' +
                '                            <div id="face%heightOnToggleDiv%' + value['id'] + '" hidden><i title="Toggle Feet Input" id="face%heightOnToggle%' + value['id'] + '"' +
                '                                                                      class="fas fa-toggle-on"></i></div>\n' +
                '                        </div>\n' +
                '                    </th>\n' +
                '                    <td>\n' +
                '                        <div style="display: flex">\n' +
                '                            <div id="face%heightFtDiv%' + value['id'] + '">\n' +
                '                                <input type="number" class="form-control layouteditordetail" id="face%heightFt%' + value['id'] + '"' +
                '                                       placeholder="Height Feet" value="' + convertIntoFtArray(value["height"])[0] + '">\n' +
                '                            </div>\n' +
                '                            &nbsp&nbsp\n' +
                '                            <div id="face%heightInDiv%' + value['id'] + '">\n' +
                '                                <input required aria-required="true" type="number" class="form-control layouteditordetail"\n' +
                '                                       id="face%heightIn%' + value['id'] + '"\n' +
                '                                       placeholder="Height Inches" value="' + convertIntoFtArray(value["height"])[1] + '">\n' +
                '                            </div>\n' +
                '                        </div>\n' +
                '                    </td>\n' +
                '                </tr>\n' +

                '                <tr>\n' +
                '                    <th>\n' +
                '                        <div style="display: flex">\n' +
                '                            <div><label for="face%widthIn%' + value['id'] + '">Face Width</label></div>\n' +
                '                            <div id="face%widthOffToggleDiv%' + value['id'] + '"><i title="Toggle Feet Input" id="face%widthOffToggle%' + value['id'] + '"' +
                '                                                                class="fas fa-toggle-off"></i></div>\n' +
                '                            <div id="face%widthOnToggleDiv%' + value['id'] + '" hidden><i title="Toggle Feet Input" id="face%widthOnToggle%' + value['id'] + '"' +
                '                                                                      class="fas fa-toggle-on"></i></div>\n' +
                '                        </div>\n' +
                '                    </th>\n' +
                '                    <td>\n' +
                '                        <div style="display: flex">\n' +
                '                            <div id="face%widthFtDiv%' + value['id'] + '">\n' +
                '                                <input type="number" class="form-control layouteditordetail" id="face%widthFt%' + value['id'] + '"' +
                '                                       placeholder="Width Feet" value="' + convertIntoFtArray(value["width"])[0] + '">\n' +
                '                            </div>\n' +
                '                            &nbsp&nbsp\n' +
                '                            <div id="face%widthInDiv%' + value['id'] + '">\n' +
                '                                <input required aria-required="true" type="number" class="form-control layouteditordetail"\n' +
                '                                       id="face%widthIn%' + value['id'] + '"\n' +
                '                                       placeholder="Width Inches" value="' + convertIntoFtArray(value["width"])[1] + '">\n' +
                '                            </div>\n' +
                '                        </div>\n' +
                '                    </td>\n' +
                '                </tr>\n' +
                '   <tr>' +
                '       <th scope="row">Face Pixel Height</th>' +
                '       <td>' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%pixel_height%' + value['id'] + '" value="' + value['pixel_height'] + '">' +
                '   </tr>' +
                '   <tr>' +
                '       <th scope="row">Face Pixel Width</th>' +
                '       <td>' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%pixel_width%' + value['id'] + '" value="' + value['pixel_width'] + '">' +
                '   </tr>' +
                '   <tr>' +
                '       <th scope="row">Face Top Left Pixel X</th>' +
                '       <td>' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%top_left_pixel_x%' + value['id'] + '" value="' + value['top_left_pixel_x'] + '">' +
                '   </tr>' +
                '   <tr>' +
                '       <th scope="row">Face Top Left Pixel Y</th>' +
                '       <td>' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%top_left_pixel_y%' + value['id'] + '" value="' + value['top_left_pixel_y'] + '">' +
                '   </tr>' +
                '   <tr>' +
                '       <th scope="row">Spots in Rotation</th>' +
                '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="face%spots_in_rotation%' + value['id'] + '" value="' + value['spots_in_rotation'] + '">' +
                '   </tr>' +
                '   <tr>' +
                '       <th scope="row">Avg Spot Length</th>' +
                '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="face%average_spot_length%' + value['id'] + '" value="' + value['average_spot_length'] + '">' +
                '   </tr>' +
                '</tbody>' +
                '</table>'+
                // 'Face ID: ' + '<input disabled class="layouteditordetail" id="face%id%' + value['id'] + '" value="' + value['id'] + '">' +
                // 'Publisher Unique Face ID: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%publisher_unique_face_id%' + value['id'] + '" value="' + value['publisher_unique_face_id'] + '">'+
                // 'Face Height INCHES: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%height%' + value['id'] + '" value="' + value['height'] + '">' +
                // 'Face Width INCHES: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%width%' + value['id'] + '" value="' + value['width'] + '">' +
                // 'Face Pixel Height: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%pixel_height%' + value['id'] + '" value="' + value['pixel_height'] + '">' +
                // 'Face Pixel Width: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%pixel_width%' + value['id'] + '" value="' + value['pixel_width'] + '">' +
                // 'Face Top Left Pixel X: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%top_left_pixel_x%' + value['id'] + '" value="' + value['top_left_pixel_x'] + '">' +
                // 'Face Top Left Pixel Y: ' + '<input  ' + enabledOrdisabled + '  class="layouteditordetail" id="face%top_left_pixel_y%' + value['id'] + '" value="' + value['top_left_pixel_y'] + '">' +
                // 'Spots in Rotation: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="face%spots_in_rotation%' + value['id'] + '" value="' + value['spots_in_rotation'] + '">' +
                '                                               </div>' +
                '                                            </div>' +
                '                                        </div>' +
                '<div id="accordionInventorySpotRowforFace' + value['id'] + '" style="display:inline-flex"></div>' +
                '                                    </div>' +
                '                                </div>'
            )
            $(document.getElementById('face%heightOffToggle%' + value['id'])).on('click', function (){
                $(document.getElementById('face%heightOffToggleDiv%' + value['id'])).attr('hidden', true)
                $(document.getElementById('face%heightOnToggleDiv%' + value['id'])).attr('hidden', false)
                $(document.getElementById('face%heightFtDiv%' + value['id'])).attr('hidden',true)
                $(document.getElementById('face%heightIn%' + value['id'])).val(value['height'])
                $(document.getElementById('face%heightFt%' + value['id'])).val(null)
            })
            $(document.getElementById('face%heightOnToggle%' + value['id'])).on('click', function (){
                $(document.getElementById('face%heightOnToggleDiv%' + value['id'])).attr('hidden', true)
                $(document.getElementById('face%heightOffToggleDiv%' + value['id'])).attr('hidden', false)
                $(document.getElementById('face%heightFtDiv%' + value['id'])).attr('hidden',false)
                $(document.getElementById('face%heightIn%' + value['id'])).val(convertIntoFtArray(value['height'])[1])
                $(document.getElementById('face%heightFt%' + value['id'])).val(convertIntoFtArray(value['height'])[0])
            })
            $(document.getElementById('face%widthOffToggle%' + value['id'])).on('click', function (){
                $(document.getElementById('face%widthOffToggleDiv%' + value['id'])).attr('hidden', true)
                $(document.getElementById('face%widthOnToggleDiv%' + value['id'])).attr('hidden', false)
                $(document.getElementById('face%widthFtDiv%' + value['id'])).attr('hidden',true)
                $(document.getElementById('face%widthIn%' + value['id'])).val(value['width'])
                $(document.getElementById('face%widthFt%' + value['id'])).val(null)
            })
            $(document.getElementById('face%widthOnToggle%' + value['id'])).on('click', function (){
                $(document.getElementById('face%widthOnToggleDiv%' + value['id'])).attr('hidden', true)
                $(document.getElementById('face%widthOffToggleDiv%' + value['id'])).attr('hidden', false)
                $(document.getElementById('face%widthFtDiv%' + value['id'])).attr('hidden',false)
                $(document.getElementById('face%widthIn%' + value['id'])).val(convertIntoFtArray(value['width'])[1])
                $(document.getElementById('face%widthFt%' + value['id'])).val(convertIntoFtArray(value['width'])[0])
            })
            $.each(features['layouts'][lindex]['faces'][findex]['spots'], function (sindex, value) {
                $("#accordionInventorySpotRowforFace" + features['layouts'][lindex]['faces'][findex]['id']).append(
                    '<div class="card" id="accordionInventorySpotRow' + value['id'] + '" style="display: inline-flex">' +
                    '                                    <div class="card-header" id="headingSpot' + value['id'] + '">' +
                    '                                        <h5 class="mb-0">' +
                    '                                            <button class="btn btn-link" data-toggle="collapse"' +
                    '                                                    data-target="#collapseSpot' + value['id'] + '"' +
                    '                                                    aria-expanded="true" aria-controls="collapseSpot' + value['id'] + '">' +
                    '                                                Spot Details' +
                    '                                            </button>' +
                    // '                        <button disabled class="btn btn-warning btn-sm" id="addSpotBtn">' +
                    // '                            Add Spot' +
                    // '                        </button>' +
                    '                                        </h5>' +
                    '                                    </div>' +
                    '                                    <div id="collapseSpot' + value['id'] + '" class="collapse show"' +
                    '                                         aria-labelledby="headingSpot' + value['id'] + '"' +
                    '                                         data-parent="#accordionInventorySpotRow' + value['id'] + '">' +
                    '                                        <div class="card-body">' +
                    '                                            <div class="row">' +
                    '                                                <div class="col" id="SpotDetail_' + value['id'] + '" style="display: grid">' +
                    '<table class="table table-striped table-bordered table-sm">' +
                    '<tbody>' +
                    '   <tr>' +
                    '       <th scope="row">Spot ID</th>' +
                    '       <td>' + '<input disabled class="layouteditordetail" id="spot%id%' + value['id'] + '" value="' + value['id'] + '">' +
                    '   </tr>' +
                    '   <tr>' +
                    '       <th scope="row">Operator Spot ID</th>' +
                    '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%plant_spot_id%' + value['id'] + '" value="' + value['plant_spot_id'] + '">' +
                    '   </tr>' +
                    '   <tr>' +
                    '       <th scope="row">Publisher Unique Spot ID</th>' +
                    '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%publisher_unique_id%' + value['id'] + '" value="' + value['publisher_unique_id'] + '">' +
                    '   </tr>' +
                    '   <tr>' +
                    '       <th scope="row">Spot Length</th>' +
                    '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%length%' + value['id'] + '" value="' + value['length'] + '">' +
                    '   </tr>' +
                    '   <tr>' +
                    '       <th scope="row">Spot SOV</th>' +
                    '       <td>' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%share_of_voice%' + value['id'] + '" value="' + value['share_of_voice'] + '">' +
                    '   </tr>' +
                    '   <tr>' +
                    '       <th scope="row">Spot Full Motion</th>' +
                    '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%full_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    '   </tr>' +                    '   <tr>' +
                    '       <th scope="row">Spot Partial Motion</th>' +
                    '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%partial_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    '   </tr>' +                    '   <tr>' +
                    '       <th scope="row">Spot Rotating</th>' +
                    '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%rotating%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    '   </tr>' +
                    '   <tr>' +
                    '       <th scope="row">Spot Interactive</th>' +
                    '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%interactive%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    '   </tr>' +
                    '   </tr>' +                    '   <tr>' +
                    '       <th scope="row">Spot Audio</th>' +
                    '       <td>' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%audio%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    '   </tr>' +
                    '</tbody>' +
                    '</table>'+
                    // 'Spot ID: ' + '<input disabled class="layouteditordetail" id="spot%id%' + value['id'] + '" value="' + value['id'] + '">' +
                    // 'Operator Spot ID: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%plant_spot_id%' + value['id'] + '" value="' + value['plant_spot_id'] + '">' +
                    // 'Publisher Unique Spot ID: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%publisher_unique_id%' + value['id'] + '" value="' + value['publisher_unique_id'] + '">' +
                    // 'Spot Length: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%length%' + value['id'] + '" value="' + value['length'] + '">' +
                    // 'Spot SOV: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%share_of_voice%' + value['id'] + '" value="' + value['share_of_voice'] + '">' +
                    // 'Spot Full Motion: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%full_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    // 'Spot Partial Motion: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%partial_motion%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    // 'Spot Rotating: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%rotating%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    // 'Spot Interactive: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%interactive%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    // 'Spot Audio: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail layouteditorselect form-control select2" id="spot%audio%' + value['id'] + '"><option>false</option><option>true</option></select>' +
                    // '<button ' + enabledOrdisabled + ' class="btn btn-danger btn-sm deleteSpotBtn" id="deleteSpotBtn%' + value['id'] + '">Delete Spot</button>' +
                    '                                               </div>' +
                    '                                            </div>' +
                    '                                        </div>' +
                    '                                    </div>' +
                    '                                </div>'
                )
                $(document.getElementById("spot%full_motion%" + value['id'])).val(value['full_motion'].toString()).change()
                $(document.getElementById("spot%partial_motion%" + value['id'])).val(value['partial_motion'].toString()).change()
                $(document.getElementById("spot%rotating%" + value['id'])).val(value['rotating'].toString()).change()
                $(document.getElementById("spot%interactive%" + value['id'])).val(value['interactive'].toString()).change()
                $(document.getElementById("spot%audio%" + value['id'])).val(value['audio'].toString()).change()

            });

        });

    });

    $("#addSpotBtn").on('click', function (){
        let appendid = $(this).parent().parent().parent().parent().parent().attr('id')
        let value="NEWSpot"
        $("#"+appendid).append(
            '<div class="card" id="accordionInventorySpotRow' + value['id'] + '" style="padding:.25rem; display: inline-flex">' +
            '                                    <div class="card-header" id="headingSpot' + value['id'] + '">' +
            '                                        <h5 class="mb-0">' +
            '                                            <button class="btn btn-link" data-toggle="collapse"' +
            '                                                    data-target="#collapseSpot' + value['id'] + '"' +
            '                                                    aria-expanded="true" aria-controls="collapseSpot' + value['id'] + '">' +
            '                                                Spot Details' +
            '                                            </button>' +
            '                                        </h5>' +
            '                                    </div>' +
            '                                    <div id="collapseSpot' + value['id'] + '" class="collapse show"' +
            '                                         aria-labelledby="headingSpot' + value['id'] + '"' +
            '                                         data-parent="#accordionInventorySpotRow' + value['id'] + '">' +
            '                                        <div class="card-body">' +
            '                                            <div class="row">' +
            '                                                <div class="col" id="SpotDetail_' + value['id'] + '" style="display: grid">' +
            'Spot ID: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%id%' + value['id'] + '">' +
            'Operator Spot  ID: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%plant_spot_id%' + value['id'] + '">' +
            'Publisher Unique Spot ID: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%publisher_unique_id%' + '">' +
            'Spot Length: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%length%' + value['id'] + '">' +
            'Spot SOV: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%share_of_voice%' + value['id'] + '">' +
            'Spot SOV: ' + '<input ' + enabledOrdisabled + ' class="layouteditordetail" id="spot%share_of_voice%' + value['id'] + '" value="' + value['share_of_voice'] + '">' +
            'Spot Full Motion: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail form-control select2" id="spot%full_motion% ' + value['id'] + '"><option>false</option><option>true</option> value="' + value['full_motion'] + '"</select>' +
            'Spot Partial Motion: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail form-control select2" id="spot%partial_motion% ' + value['id'] + '"><option>false</option><option>true</option> value="' + value['partial_motion'] + '"</select>' +
            'Spot Rotating: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail form-control select2" id="spot%rotating% ' + value['id'] + '"><option>false</option><option>true</option> value="' + value['rotating'] + '"</select>' +
            'Spot Interactive: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail form-control select2" id="spot%interactive% ' + value['id'] + '"><option>false</option><option>true</option> value="' + value['interactive'] + '"</select>' +
            'Spot Audio: ' + '<select ' + enabledOrdisabled + ' class="layouteditordetail form-control select2" id="spot%audio% ' + value['id'] + '"><option>false</option><option>true</option> value="' + value['audio'] + '"</select><br>' +
            // '<button ' + enabledOrdisabled + ' class="btn btn-danger btn-sm" id="deleteSpotBtn%"' + value['id'] + '">Delete Spot</button>' +
            '                                               </div>' +
            '                                            </div>' +
            '                                        </div>' +
            '                                    </div>' +
            '                                </div>'
        )
    })
    $('.deleteSpotBtn').on('click',function (){
        // console.log(this.id)
        let spot_id = this.id.split('%')[1]
        let divid = '"#accordionInventorySpotRow'+spot_id+'"'
        $("#accordionInventorySpotRow"+spot_id).remove()
    })
}
