import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {linestringstyleFunction, linestringstyleFunction2021} from "../map_styles/lineStyle";
import {selectedStyle} from "../map_styles/pointStyles";
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";
import {geojsonplaceDefaultStyle} from "../map_styles/polyStyles";

// layer that displays assignment geojsons
export const assignmentsource = new VectorSource({});
export const assignmentlayer = new VectorLayer({
    source: assignmentsource,
    style: linestringstyleFunction,
    title: "2016 Assignments",
    zIndex:3
});
export const assignmentsource2021 = new VectorSource({});
export const assignmentlayer2021 = new VectorLayer({
    source: assignmentsource2021,
    style: linestringstyleFunction2021,
    title: "2021 Assignments",
    zIndex:2
});
export const placebasedassignmentsource = new VectorSource({});
export const placebasedassignmentlayer = new VectorLayer({
    source: placebasedassignmentsource,
    style: geojsonplaceDefaultStyle
});
//
// export const heresource = new VectorSource({});
// export const herelayer = new VectorLayer({
//     source: heresource,
//     style: linestringstyleFunction
// });
//

//create layer for inventory geojson display, used for inventory updates, added to map in map layergroup below
export const rotateSource = new VectorSource({});
export const rotateLayer= new VectorLayer({
    className:"rotateLayer",
    source: rotateSource,
    style: selectedStyle
});
export const filteredInventorySource = new VectorSource({});
export const filteredInventoryLayer= new VectorLayer({
    title: 'Filtered Inventory',
    className:"filteredInventoryLayer",
    source: filteredInventorySource,
    style: new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: [255, 255, 191, 1]
            }),
            stroke: new Stroke({
                color: [55, 56, 58, 1]
            })
        })
    })
})
export const coordinateSearchSource = new VectorSource({});
export const coordinateSearchLayer = new VectorLayer({
    className:"coordinateSearchLayer",
    source: coordinateSearchSource,
    style: new Style({
        text: new Text({
            text: '\uf3c5',
            font: '900 25px "Font Awesome 5 Free',
            fill: new Fill({
                color: [0, 131, 154]
            }),
            stroke: new Stroke({
                color: [0, 131, 154]
            })

        })
    })
});

//clears geojson from assignment layer to be able to show only current assignments
export function clearAssignmentLayer() {
    assignmentlayer.getSource().clear();
    assignmentlayer2021.getSource().clear();
    placebasedassignmentlayer.getSource().clear();
}

export function clearCoordinateSearchLayer() {
    coordinateSearchLayer.getSource().clear()
}

export function clearRotateLayer() {
    rotateLayer.getSource().clear();
}

export function clearFilteredLayer() {
    filteredInventoryLayer.getSource().clear();
}