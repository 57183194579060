import {map} from "../map";
import {transform} from "ol/proj";
import $ from "jquery";
import {currentpanelwkt} from "./consts";
import {createEmpty, extend as OLextend} from 'ol/extent';
import {
    assignmentsource,
    filteredInventoryLayer,
    placebasedassignmentsource,
    rotateSource
} from "../layers/activeLayers";
import {geoToH3} from "h3-js";

export function transform4326to3857 (lon,lat){
    return transform([lon,lat],'EPSG:4326', 'EPSG:3857' )
}

export function setNewMapCenter(newcoords){
    map.getView().setCenter(newcoords);
    map.getView().setZoom(18)
}

export function createPointWKTfromCoords(lon,lat) {
    $(currentpanelwkt).text('POINT('+lon+' '+lat+')')
}

export function getExtentofAssignmentsAndPoints(){
    let extent = createEmpty();
    OLextend(extent,rotateSource.getExtent())
    OLextend(extent,assignmentsource.getExtent())
    map.getView().fit(extent)
    map.getView().setZoom(map.getView().getZoom()-.2)
}
export function getExtentofPlaceAssignmentsAndPoints(){
    let extent = createEmpty();
    OLextend(extent,rotateSource.getExtent())
    OLextend(extent,placebasedassignmentsource.getExtent())
    map.getView().fit(extent)
    map.getView().setZoom(map.getView().getZoom()-.2)
}

export function getExtentOfSubFilteredPoints(){
    let extent = createEmpty();
    // console.log(filteredInventoryLayer)
    OLextend(extent,filteredInventoryLayer.getExtent())
    map.getView().fit(extent)
}

export function getH3Value(lat, lon, res){
    return geoToH3(lat, lon, res)
}