import {transform} from 'ol/proj';
import {createStringXY} from 'ol/coordinate';
import {_blank, coordinateDisplayDiv} from "../supportFunctions/consts";

//take the click event pixel and extract the coordinates for display
export function  clickedLonLat(event) {
    const stringifyFunc = createStringXY(6);
    //coordinates need to be transformed into understandable lat/lon
    const coordinate = stringifyFunc(transform([event.coordinate[0], event.coordinate[1]], 'EPSG:3857', 'EPSG:4326'));
    const splitcoordinates = coordinate.split(',');
    const clickedlat = splitcoordinates[1].trim();
    const clickedlon = splitcoordinates[0].trim();
    //go to the display function
    coordinateDisplay([clickedlon,clickedlat])
    return ([clickedlon,clickedlat])
}

//display the coordinates in the infobox and get gmaps links
export function coordinateDisplay(coordinate) {
    let gmapslink = createGmapsLinks(coordinate)
    coordinateDisplayDiv.html('<div id=infobox><br>' +
        "Clicked Coordinates: <a href =" + gmapslink + " target=" + _blank + ">" + '<span id="currentCoordinate">' + coordinate + '</span>' + ' ' + '  </a><button class="clipboard" id="coordcopy" data-clipboard-text="' + coordinate + '"><i class="far fa-clipboard"></i></button><button id="birdseyeAvail" hidden><i class="fas fa-eye"></i></button></br>');
}

//take the coordinates and create google map links
export function createGmapsLinks(coordinate) {
    let lat = coordinate[1]
    let lon = coordinate[0]
    // const streetviewlink = "https://maps.google.com/maps?q=&layer=c&cbll=" + lat + "," + lon;
    return  "https://www.google.com/maps/search/?api=1&query=" + lat + "," + lon;
}


