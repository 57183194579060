import $ from "jquery"
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css'
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import {searchControl} from "./search/searchControl";
import {createAssignments, deleteAllAssignments, reCalcAssignments} from "./assignments/assignmentActions";
import {refreshFilter, removeFilter} from "./points/pointFilters";
import select2 from 'select2';
import {
    hideInsightsReference,
    hideInventoryManagementSidebar,
    hideMapSideBar,
    hideToolSidebar,
    showInsightsReference,
    showMapSideBar,
    showToolSidebar
} from "./supportFunctions/consts";
import {apiGetFrame} from "./points/pointHandler";
import {apiGetAssignments} from "./assignments/assignmentHandler";
import {filterbyFrameID} from "./infoDisplays/filteredInventory";
import ClipboardJS from '../node_modules/clipboard/dist/clipboard.min'
import {getAccounts,} from "./supportFunctions/accounts";
import {representationTypeSelectVals} from "./supportFunctions/apiTypes";
import {intialiseInvetoryEdit} from "./inventoryManagement/editInventory";
import {createAccountReviewTable} from "./infoDisplays/underReviewPlants";
import {streetDefaultStyle} from "./map_styles/lineStyle";
import {getSingleSpotMeasures, getSummaryMeasures} from "./insights/insightsFunctions";
import {
    lookupFrameID,
    lookupSpotID,
    searchAudienceSegemnts,
    searchMarkets,
    updateLatLonTool
} from "./search/referenceSearch";
import {
    changeStatus,
    createAccount,
    createRepresentation,
    loadclassificationCSV,
    loadPlaceAssignmentCSV,
    loadstatusCSV,
    populateClassificationTypes,
    populateStatusTypes
} from "./infoDisplays/toolTabFunctions";
import {createInventoryForm} from "./infoDisplays/createInventoryForm";
import {convertFttoIn} from "./supportFunctions/mathFunctions";
import {createAcknowledgements} from "./supportFunctions/acknowledgements.js";


$( document ).ready(function() {
    let clipboard = new ClipboardJS('.clipboard');
    select2($);
    searchControl()
    $("#createAssignment").on('click', function () {
        createAssignments()
    })
    $("#deleteAllAssignments").on('click', function () {
        deleteAllAssignments()
    })
    $("#reCalcAssignments").on('click', function () {
        reCalcAssignments()
    })
    $("#currentpanelid").on('click',function () {
        apiGetFrame($('#currentpanelid').text())
        streetDefaultStyle()
    })
    $('#getassignments').on('click', function () {
        apiGetAssignments()
    })
    $("#filterFrameIDSubmit").on('click',function () {
        filterbyFrameID()
    })
    $("#refreshFilteredInventory").on('click',function () {
        refreshFilter()
    })
    $("#clearFilterbtn").on('click',function () {
        removeFilter()
    })
    // $('#fourthtabTab').on('click', function () {
    //     managementTabDisplay()
    //     clearInfoDiv()
    //     clearCoordinateDisplay()
    //     clearOtherInfoDiv()
    //     showInventoryManagementSidebar()
    //     $("#formClassificationSelector").select2({
    //         placeholder: "Select Classification",
    //         data: classificationSelectVals
    //     })
    //     $("#formMediaSelector").select2({
    //         placeholder: "Select Media",
    //         data: mediaSelectVals
    //     })
    //     $("#formConstructionTypeSelector").select2({
    //         placeholder: "Select Construction",
    //         data: constructionSelectVals
    //     })
    //     $("#formIlluminationType").select2({
    //         placeholder: "Select Illumination",
    //         data: illuminationSelectVals
    //     })
    //     $("#formStatusSelector").select2({
    //         placeholder: "Select Illumination",
    //         data: statusSelectVals
    //     })
    //
    //
    // })
    $("#mapTab_navTab").on('click',function () {
        hideInventoryManagementSidebar()
        hideToolSidebar()
        hideInsightsReference()
        showMapSideBar()

    })
    $("#toolsTab_navTab").on('click',function () {
        // clearInfoDiv()
        // clearOtherInfoDiv()
        // clearCoordinateDisplay()
        // hideAssignmentsButton()
        hideMapSideBar()
        hideInventoryManagementSidebar()
        hideInsightsReference()
        showToolSidebar()
        createAccountReviewTable()
    })
    $("#insightsTab_navTab").on('click',function (){
        // clearInfoDiv()
        // clearOtherInfoDiv()
        hideInventoryManagementSidebar()
        hideToolSidebar()
        // hideAssignmentsButton()
        hideMapSideBar()
        hideInventoryManagementSidebar()
        showInsightsReference()
    })

    $("#saveIMTableEdits").on("click", function (){
        editInventoryRecord()
    })
    $("#multi_submit").on("click",function (){
        getSingleSpotMeasures()
    })
    $("#sum_submit").on("click",function (){
        getSummaryMeasures()
    })
    $("#segmentReferenceSearchBtn").on("click",function (){
        searchAudienceSegemnts()
    })
    $("#marketReferenceSearchBtn").on("click",function (){
        searchMarkets()
    })
    $("#latLonToolbtn").on("click",function (){
        updateLatLonTool()
    })
    $("#lookuptoolFrameBtn").on("click", function (){
        lookupFrameID()
    })
    $("#lookupToolSpotBtn").on("click",function (){
        lookupSpotID()
    })
    $("#classChangeHeader").on("click", function (){
        populateClassificationTypes()
    })
    $("#statusChangeHeader").on("click", function (){
        populateStatusTypes($("#statusChangeDropDown"))
    })
    $("#statusToolHeading").on("click", function (){
        populateStatusTypes($("#statusToolDropDown"))
    })
    $("#classChangeFileInput").change(loadclassificationCSV)
    $("#statusChangeFileInput").change(loadstatusCSV)
    $("#statusToolbtn").on("click", function (){
        changeStatus([$("#statusframeid").val()],$("#statusToolDropDown").select2('data'))
    })
    $("#createAccountToolHeading").on('click',function (){
        $("#createAccountToolRepTypeDropDown").select2({
            theme: 'bootstrap4',
            placeholder: "Select Rep Type",
            data: representationTypeSelectVals
        })
    })
    $("#createRepToolHeading").on('click',function (){
        $("#createRepToolRepTypeDropDown").select2({
            theme: 'bootstrap4',
            placeholder: "Select Rep Type",
            data: representationTypeSelectVals
        })
    })
    $("#createAccountToolbtn").on('click',function (){
        createAccount()
    })
    $("#createRepToolbtn").on('click',function (){
        createRepresentation()
    })
    $("#createToolRefreshAccountsBtn").on('click', function (){
        getAccounts()
    })
    $("#placeAssignFileInput").change(loadPlaceAssignmentCSV)
    $("#createInvFormLaunch").on("click", function (){
        createInventoryForm()
    })
    $("#editToolbtn").on("click", function (){
        intialiseInvetoryEdit($("#editframeid").val())
    })
    $("#convertMeasBtn").on("click", function (){
        let inches = convertFttoIn($("#inputConvertFeet").val(),$("#inputConvertInches").val())
        $("#measConvertResults").html(
            '<h3>' + inches + ' Inches </h3>'
        )
    })
    $("#filterinvtypbut").on('click', async function (){
        $("#filterModal").modal('show');
    })
    $("#aboutButton").on('click', async function (){
        createAcknowledgements();
    })



});



