import $ from 'jquery'
import {
    showAssignmentManagementButtons,
    showAssignmentTable,
    showLegacyAssignmentTable
} from "../supportFunctions/consts";
import {deleteAllAssignments, updateAssignmentsFromForm} from "./assignmentActions";
import {flashStreet} from "../lines/lineFunctions";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import {flashPlace} from "../polygons/polyFunctions";
import {
    directionBuilder,
    modeBuilder,
    networkYearBuilder,
    roadClassBuilder,
    streetNameBuilder
} from "../infoDisplays/tableParamBuilders.js";


let assignmentTable = null
let legacyAssignmentTable = null

export function getAssignmentIdsFromTable() {
    let assignmentlist = []
    assignmentlist.length = 0
    let assigntable = Tabulator.findTable("#assignmentTable")[0]
    $.each(assigntable.getData(), function (index,value) {
        assignmentlist.push(value['assignment_id'])
    })
    return assignmentlist
}

export function createPlaceBasedAssignmentTable(flatAssignmentProps){
    // console.log(flatAssignmentProps)
    showAssignmentManagementButtons()
    showAssignmentTable()
    $('#reCalcAssignments').prop('hidden', true)
    $('#createAssignment').prop('hidden', true)
    if(assignmentTable){
        assignmentTable.destroy()
    }
    let deleteicon = function(cell, formatterParams, onRendered){ //plain text value
        return "<i class='fas fa-trash-alt'></i>";
    };
    $("#assignmentHeaderText").html('<h6>'+flatAssignmentProps.length + ' Total Assignments</h6>')
    assignmentTable = new Tabulator ("#assignmentTable", {
        selectable: 1,
        data: flatAssignmentProps,
        layout: "fitData",
        initialSort: [{column: "assignment_id", dir: "asc"}],
        columns: [
            {
                title: "Assignment ID",
                field: "assignment_id",
            },
            {
                title: "Place ID",
                field: "place_id",
            },
            {
                title: "HERE Place ID",
                field: "here_place_id",
            },
            {
                title: "Place Name",
                field: "place_name",
            },
            {
                title: "Place Type",
                field: "place_type_name",
            },
            {
                title: "Entrances",
                field: "entrances_cnt",
            },
            {
                title: "Floors",
                field: "floors_cnt",
            },
            {
                title: "Concourses",
                field: "concourse_cnt",
            },
            {
                title: "Platforms",
                field: "platforms_cnt",
            },
            {
                title: "Gates",
                field: "gates_cnt",
            },
            {
                title: "Address",
                field: "street_address",
            },
            {
                formatter: deleteicon,
                width: 40,
                hozAlign: "center",
                cellClick: function (e, cell) {
                    deleteAllAssignments(cell.getRow().getData().assignment_id)
                }
            },

        ]
    })
    assignmentTable.on('rowClick',function (e, row,) {
        let selectedRowPlaceID = row.getData().place_id;
        flashPlace(selectedRowPlaceID)
    })
}

export function createAssignmentTable (flatAssignmentProps){
    showAssignmentManagementButtons()
    showAssignmentTable()
    if(assignmentTable){
        assignmentTable.destroy()
    }
    let deleteicon = function(cell, formatterParams, onRendered){ //plain text value
        return "<i class='fas fa-trash-alt'></i>";
    };
    let saveicon = function(cell, formatterParams, onRendered){ //plain text value
        return "<i class='fas fa-save'></i>";
    };
    $("#assignmentHeaderText").html('<h6>'+flatAssignmentProps.length + ' Total Assignments</h6>')
    assignmentTable = new Tabulator ("#assignmentTable",{
        columnDefaults:{
            headerTooltip:true,
        },
        selectable: 1,
        data: flatAssignmentProps,
        layout: "fitData",
        initialSort:[{column:"assignment_id",dir:"desc"}],
        columns:[
            {
                title: "Assignment ID",
                field: "assignment_id",
            },
            {
                title: "Network",
                field: "network_year",
                headerFilter: "list",
                headerFilterParams: {valuesLookup:"active"},
                headerFilterFunc: "="
            },
            {
                title: "Segment ID",
                field: "segment_id",
            },
            {
                title: "Street",
                field: "st_name",
                headerFilter: "list",
                headerFilterParams: {valuesLookup:"active"},
                headerFilterFunc: "="
            },
            {
                title: "Mode",
                field: "mode",
                editor:"list", editorParams:{values:["B", "P"]},
                headerFilter: "list",
                headerFilterParams: {valuesLookup:"active"},
                headerFilterFunc: "="
            },
            {
                title: "Right",
                field: "right_read",
                editor:"list", editorParams:{values:[true, false]},
                headerTooltip: "Right Hand Read"
            },
            {
                title: "Perp.",
                field: "perpendicular",
                editor:"list", editorParams:{values:[true, false]},
                headerTooltip: "Perpendicular Read"
            },
            {
                title: "Center",
                field: "center_read",
                editor:"list", editorParams:{values:[true, false]},
                headerTooltip: "Center Read"
            },
            {
                title: "DFTA",
                field: "dfta",
                headerTooltip: "Distance From the Audience"
            },
            {
                title: "Pl. Adj.",
                field: "placement_adjustment_factor",
                headerTooltip: "Placment Adjustment Factor"
            },
            {
                title: "Rd Class",
                field: "func_class",
                headerTooltip: "Road Class",
                headerFilter: "list",
                headerFilterParams: {valuesLookup:"active"},
                headerFilterFunc: "="
            },
            {
                title: "ST Dir",
                field: "seg_dir",
                headerTooltip: "Street Direction",
                headerFilter: "list",
                headerFilterParams: {valuesLookup:"active"},
                headerFilterFunc: "="
            },
            {
                title: "Veh Circ",
                field: "veh_circ",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "Veh AADT",
                field: "veh_aadt",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "Ped AADT",
                field: "ped_circ",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                formatter:deleteicon,
                width:40,
                hozAlign:"center",
                cellClick:function(e, cell){deleteAllAssignments(cell.getRow().getData().assignment_id)}
                },
            {
                formatter:saveicon,
                width:40,
                hozAlign:"center",
                cellClick:function(e, cell){updateAssignmentsFromForm(cell.getRow().getData())}
            },
        ]
    })
    assignmentTable.on('rowClick',function (e, row,) {
        let selectedRowSegment = row.getData().segment_id;
        let network_year = row.getData().network_year
        flashStreet(selectedRowSegment,network_year)
    })
}

export function createLegacyAssignmentTable (flatAssignmentProps){
    showLegacyAssignmentTable()
    if(legacyAssignmentTable){
        legacyAssignmentTable.destroy()
    }
    // $("#assignmentHeaderText").html('<h6>'+flatAssignmentProps.length + ' Total Assignments</h6>')
    legacyAssignmentTable = new Tabulator ("#legacyassignmentTable",{
        selectable: 1,
        data: flatAssignmentProps,
        layout: "fitData",
        initialSort:[{column:"assignment_id",dir:"asc"}],
        columns:[
            {
                title: "Assignment ID",
                field: "assignment_id",
            },
            {
                title: "Segment ID",
                field: "segment_id",
            },
            {
                title: "Street",
                field: "st_name",
            },
            {
                title: "Direction",
                field: "direction",
            },
            {
                title: "Center",
                field: "center",
            },
            {
                title: "Rd Class",
                field: "func_class",
            },
            {
                title: "Veh AADT",
                field: "veh_aadt",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "Ped AADT",
                field: "ped_aadt",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            }
        ]
    })
}

