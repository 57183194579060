import $ from 'jquery'
import {inventorySearch} from "./frameSearch";
import {searchbuttons, searchfield} from "../supportFunctions/consts";
import {coordinateSearch} from "./coordinateSearch";
import {segmentSearch} from "./segmentSearch";

// set function to set the value of the dropdown based on the option selected
export function searchControl () {
    $('#searchdrop a').on('click', function () {
        searchbuttons.html($(this).html());
        searchbuttons.val($(this).text());
    })

}
//set event for pressing enter in search field
searchfield.on('keypress', function (e) {
    if(e.keyCode == 13){
        e.preventDefault();
        searchbuttonactions();
    }
});

//set event for clicking the search button after a value has been picked
searchbuttons.on('click',function () {
    searchbuttonactions()
})

// set function for handling search routing based on the value selected
export function searchbuttonactions() {
    let val = searchfield.val();
    if (searchbuttons.val() === "Search by Spot ID") {
        inventorySearch(val)
    }
    else if (searchbuttons.val() === "Search by Lon, Lat") {
        coordinateSearch(val)
    }
    else if (searchbuttons.val() === "Search by SegmentID 2016") {
        segmentSearch(val, 2016)
    }
    else if (searchbuttons.val() === "Search by SegmentID 2021") {
        segmentSearch(val, 2021)
    }
}